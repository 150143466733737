<template>
  <div class="container ">
    <el-card class="mb-4">
      <!-- <h1 class="text-center text-2xl font-bold mb-4"> المبيعات </h1> -->

      <!-- <qrcode-drop-zone></qrcode-drop-zone>
<qrcode-capture></qrcode-capture> -->


      <div class="row d-flex">
        <!-- <qrcode-capture :formats="['qr_code', 'code_128']"></qrcode-capture> -->
        <!-- Customers Section -->
        <el-card class="customer-list col-span-1 col-6 overflow-x-auto max-h-500">
          <div class="col-4">
            <qrcode-stream @decode="onDecode" @init="onInit" @detect="onDetect" @error="onError"></qrcode-stream>

          </div>
          <h2 class="text-lg font-semibold mb-0">الزبائن </h2>
          <div class="col-12 row d-flex flex-row p-2 py-0">
            <el-select v-model="buyerType" @change="fetchCustomers" placeholder=" نوع الزبون"
              class="mb-0 col-4 w-30 p-0">
              <el-option label="موظف" value="employee" />
              <el-option label="طالب" value="student" />
              <el-option label="زائر" value="visitor" />
            </el-select>


            <el-input v-model="searchQuery" placeholder="ابحث عن  الزبون بواسطة الاسم أو الرقم التعريفي"
              @input="filterCustomers" @change="filterCustomers" class="mb-0 col-4 w-70 p-0" />
          </div>
          <div class="table-wrapper">
            <el-table :data="filteredCustomers" border>
              <el-table-column prop="name" label="الاسم" />
              <el-table-column prop="daily_allowed_purchase_value" label="القيمة المسموحة" />
              <el-table-column prop="id" label="الرقم التعريفي" />
              <el-table-column label="الإجراء">
                <template #default="scope">
                  <el-button @click="selectCustomer(scope.row)" type="primary">اختر</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>

        <!-- Meals Section -->
        <el-card v-if="selectedCustomer" class="meal-section col-span-1 col-6 overflow-x-auto max-h-500">
          <h2 class="text-lg font-semibold mb-2">الزبون : {{ selectedCustomer.name }}</h2>
          <el-input v-model="mealSearchQuery" placeholder="ابحث عن الأصناف" @input="filterMeals" class="mb-4" />
          <h3 class="mb-2">اختر الأصناف:</h3>
          <div class="table-wrapper">
            <el-table :data="filteredMeals" border>
              <el-table-column prop="name" label="اسم الصنف" />
              <el-table-column prop="price" label="السعر" />
              <el-table-column label="الإجراء">
                <template #default="scope">
                  <el-button @click="addToBill(scope.row)" type="success">أضف إلى الفاتورة</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </div>

      <!-- Bill Section -->
      <el-card class="bill-section col-span-1 overflow-x-auto max-h-500 row">
        <h2 class="text-lg font-semibold mt-0">الفاتوره </h2>
        <el-select v-model="selectedPaymentMethod" placeholder=" نوع الفاتوره" class="mb-0 col-4 w-15 p-0">
          <el-option v-for="PM in paymentMethods" :label="PM.label" :value="PM.value" />

        </el-select>
        <div class="table-wrapper">
          <el-table :data="kitchenBillItems" border stripe>
            <el-table-column prop="name" label="اسم الصنف" />
            <el-table-column prop="quantity" label="الكمية">

              <template #default="scope">

                <div class="m-0 p-0 d-flex justify-content-between">
                  <el-button
                    @click="if (scope.row.quantity > 1) { scope.row.quantity--; } else { removeFromBill(scope.row.meal_id) }"
                    type="danger">-</el-button>

                  <label>{{ scope.row.quantity }}</label>
                  <el-button @click="scope.row.quantity++" type="primary">+</el-button>

                </div>
              </template>
            </el-table-column>
            <el-table-column prop="meal_price" label="سعر القطعه" />
            <el-table-column prop="" label="السعر">
              <template #default="scope">
                {{ scope.row.meal_price * scope.row.quantity }}
              </template>
            </el-table-column>
            <el-table-column label="الإجراء">
              <template #default="scope">
                <el-button @click="removeFromBill(scope.row.meal_id)" type="danger">إزالة</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="d-flex flex-column align-items-end">

          <p class="font-bold mt-2">الإجمالي: {{ totalPrice }} دينار</p>
          <el-button v-if="selectedPaymentMethod != ''" @click="submitBill" type="success" class="mt-4  w-25"> تأكيد
            عملية
            البيع
          </el-button>
        </div>
      </el-card>
    </el-card>
  </div>
</template>

<script>

import axios from 'axios';


import { ElCard, ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElButton, ElNotification } from 'element-plus';
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
});

// Interceptors for auth token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default {
  components: {
    ElCard,
    ElSelect,
    ElOption,
    ElInput,
    ElTable,
    ElTableColumn,
    ElButton,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,

  },
  data() {
    return {
      customers: [],
      filteredCustomers: [],
      meals: [],
      filteredMeals: [],
      kitchenBillItems: [],
      buyerType: 'employee',
      selectedCustomer: null,
      searchQuery: '',
      mealSearchQuery: '',
      totalPriceValue: '',
      restrictedMealIds: [],
      paymentMethods: "",
      selectedPaymentMethod: "",

      visitorData:
        [{
          id: null,
          name: null,
        }]
      ,
    };
  },
  computed: {
    totalPrice() {
      this.totalPriceValue = this.kitchenBillItems.reduce((sum, item) => sum + item.meal_price * item.quantity, 0);

      return this.kitchenBillItems.reduce((sum, item) => sum + item.meal_price * item.quantity, 0);
    },
  },
  methods: {
    onDecode(result) {
      console.log('Decoded QR Code:', result); // Debugging output
      this.closeQrScanner();
      // Handle the reservation data from the QR code
      try {
        this.decodedData = JSON.parse(result);
        this.dialogVisible = true;
      } catch (error) {
        alert('Invalid QR code format');
      }
    },
    onInit(promise) {
      promise.catch(error => {
        if (error.name === 'NotAllowedError') {
          alert('User denied camera access');
        } else if (error.name === 'NotFoundError') {
          alert('No camera found on this device');
        } else {
          alert('Error initializing camera: ' + error.message);
        }
      });
    },
    onError(error) {
      console.error('QR Code reading error:', error); // Debugging output
      alert('Error reading QR code: ' + error.message);
    },
    onDetect(result) {





      // Debugging output
      this.decodedData = JSON.stringify(result);
      this.dialogVisible = true;
      // this.closeQrScanner();




      const jsonString = result[0].rawValue;
      const jsonStringArray = JSON.parse(jsonString);
      this.buyerType = jsonStringArray[0];

      this.searchQuery = jsonStringArray[1];
      this.fetchCustomers();
    },

    async fetchCustomers() {
      this.kitchenBillItems = [];
      this.selectedCustomer = null;
      this.searchQuery = '';
      this.mealSearchQuery = '';
      this.customers = "";
      this.filteredCustomers = "";
      if (this.buyerType != 'visitor') {
        const endpoint = this.buyerType === 'student'
          ? '/students-customers'
          : '/employees-customers';

        try {
          const response = await api.get(endpoint);
          this.customers = response.data;
          this.filteredCustomers = response.data;
        } catch (error) {
          console.error('Error fetching customers:', error);
        }

        const query = this.searchQuery.toLowerCase();
        this.filteredCustomers = this.customers.filter(customer => {
          return (
            customer.name.toLowerCase().includes(query) ||
            customer.id == query
          );
        });
      }
      else if (this.buyerType == 'visitor') {

        this.customers = this.visitorData;
        this.filteredCustomers = this.visitorData;
      }
    },


    async totalPriceForToday() {
      const response = await api.post('/total-price-for-today', {
        // customer:this.selectedCustomer,
        buyerType: this.buyerType,
        selectCustomer: this.selectedCustomer.id,

      });
      console.log(this.buyer_type);
    },
    async fetchMeals() {
      try {
        const response = await api.get('/meals');
        this.meals = response.data;

        // Remove restricted meals
        this.meals = this.meals.filter(meal => !this.restrictedMealIds.includes(meal.id));

        // Filter meals for display
        this.filterMeals();
      } catch (error) {
        console.error('Error fetching meals:', error);
      }
    },
    async filterCustomers() {

      const query = this.searchQuery.toLowerCase();
      this.filteredCustomers = this.customers.filter(customer => {
        return (
          customer.name.toLowerCase().includes(query) ||
          customer.id.toString().includes(query)
        );
      });
    },
    async filterMeals() {
      const query = this.mealSearchQuery.toLowerCase();
      this.filteredMeals = this.meals.filter(meal => {
        const isRestrictedMeal = this.restrictedMealIds.includes(meal.id);
        const matchesSearchQuery = meal.name.toLowerCase().includes(query);

        // If a student is selected, filter out restricted meals
        if (this.selectedCustomer && this.selectedCustomer.type === 'student') {
          return !isRestrictedMeal && matchesSearchQuery;
        }

        // Show all meals for employees or if no student is selected
        return matchesSearchQuery;
      });
    },

    selectCustomer(customer) {

      this.meals = [];
      this.filteredMeals = [];

      this.selectedCustomer = customer;
      this.kitchenBillItems = [];

      this.paymentMethods = Array();
      this.selectedPaymentMethod = "";

      if (this.buyerType == 'student')

        if (this.selectedCustomer.meal_fees.length > 0) {
          this.paymentMethods = Array(
            { label: 'محفظة', value: 'walet' },
            { label: 'اشتراك', value: 'subscription' },
            { label: 'نقدي', value: 'cash' }
          );
        } else {

        }


      // Extract restricted meal IDs
      this.restrictedMealIds = [];
      if (customer.student_restricted_meal) {
        for (let i = 0; i < customer.student_restricted_meal.length; i++) {
          this.restrictedMealIds.push(customer.student_restricted_meal[i].meal_id);
        }
      }

      this.totalPriceForToday();
      // Fetch meals and filter out restricted meals
      this.fetchMeals();
    },

    addToBill(meal) {
      console.log(this.selectedCustomer);
      const existingItem = this.kitchenBillItems.find(item => item.meal_id === meal.id);
      if (existingItem) {
        existingItem.quantity++;
      } else {
        this.kitchenBillItems.push({
          meal_id: meal.id,
          name: meal.name,
          meal_price: meal.price,
          quantity: 1,
        });
      }
    },

    removeFromBill(mealId) {
      const index = this.kitchenBillItems.findIndex(item => item.meal_id === mealId);
      if (index !== -1) {
        this.kitchenBillItems.splice(index, 1);
      }
    },
    ignoreError() {
      const debounce = (callback, delay) => {
        let tid;
        return function (...args) {
          const ctx = self;
          tid && clearTimeout(tid);
          tid = setTimeout(() => {
            callback.apply(ctx, args);
          }, delay);
        };
      };

      const _ = window.ResizeObserver;
      window.ResizeObserver = class ResizeObserver extends _ {
        constructor(callback) {
          callback = debounce(callback, 20);
          super(callback);
        }
      };
    },
    async submitBill() {
      const billData = {
        buyer_id: this.selectedCustomer.id,
        buyer_type: this.buyerType,
        billTotalPrice: this.totalPriceValue,
        items: this.kitchenBillItems.map(item => ({
          meal_id: item.meal_id,
          quantity: item.quantity,
          meal_price: item.meal_price,

        })),
      };

      try {
        const response = await api.post('/buying-from-kitchen', billData);
        console.log('Bill submitted:', response.data);
        this.kitchenBillItems = [];
        this.selectedCustomer = null;
        this.searchQuery = '';
        this.mealSearchQuery = '';
        this.fetchCustomers();
      } catch (error) {

        ElNotification({
          title: 'حدث خطأ',
          message: error.response.data.errors.message + "\n" + error.response.data.errors.message,
          position: 'top-left',
          duration: 0,
          type: 'error',
        });

      }
    },
  },
  mounted() {
    this.fetchCustomers();
    this.ignoreError();

  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}

.table-wrapper {
  max-height: 179px;
  overflow-x: auto;
  overflow-y: auto;
  /* Allows vertical scroll if needed */
}
</style>
