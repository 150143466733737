<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div :class="{'dark-mode': isDarkMode}" class="card my-4">
          <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
            <div class="d-flex justify-content-around">
       
              <h6 class="text-white text-capitalize ps-3">Manage Students</h6>
              <el-input
                style="width: 200px;"
                v-model="searchQuery"
                placeholder="Search students..."
                class="search-input"
                @input="fetchStudents"
              />

              <!-- Print All Student With Their Information -->
              <el-button @click="fetchPrintStudents" type="warning" circle class="mr-5">
                <el-icon style="vertical-align: middle">
                  <Printer />
                </el-icon>
              </el-button>

              <el-button @click="dialogVisible = true" type="success" circle class="mr-5">
                <el-icon style="vertical-align: middle">
                  <Document />
                </el-icon>
              </el-button>

            </div>
          </div>
          <div>
            <el-table :data="students" style="width: 100%" :cell-class-name="highlightIdCell">
          
              <el-table-column type="expand" :label="$t('operations')" width="100" :cell-class-name="operationCellClass">
                <template #default="scope">
                  <el-button @click="showStudent(scope.row)" type="primary" size="mini">{{$t("show")}}</el-button>
                  <el-button @click="editStudent(scope.row)" type="warning" size="mini">{{$t('edit')}}</el-button>
                  <el-button @click="deleteStudent(scope.row.id)" type="danger" size="mini">{{$t('delete')}}</el-button>
                  <!-- <el-button @click="printStudent(scope.row)" type="info" circle size="large">
                    <el-icon style="vertical-align: middle">
                      <Printer />
                    </el-icon>
                  </el-button> -->
                </template>
              </el-table-column>
              <el-table-column property="id" :label="$t('fileNumberOfStudent')" width="200" :cell-class-name="highlightIdCell"/>
              <el-table-column property="gender" :label="$t('gender')" width="120">
                <template #default="scope">
                  <el-radio-group v-model="scope.row.gender" @change="() => updateStudentGender(scope.row.id, scope.row.gender)">
                    <el-radio :label="'male'">Male</el-radio>
                    <el-radio :label="'female'">Female</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column property="name" :label="$t('name')" width="120" />
              <el-table-column property="passport_num" :label="$t('passportNumber')" width="120" />
              <el-table-column property="date_of_birth" :label="$t('dateOfBirth')" width="120" />
              <el-table-column property="address" :label="$t('address')" width="240" />
              <el-table-column property="year" :label="$t('year')" width="80" />
              <el-table-column property="has_books" :label="$t('gotBooks?')" width="80" />
              <el-table-column property="national_number" :label="$t('nationalNumber')" width="240" />
              <el-table-column property="parent.first_name" :label="$t('parentFirstName')" width="120" />
              <el-table-column property="parent.last_name" :label="$t('parentLastName')" width="120" />
              <el-table-column property="class.name" :label="$t('class')" width="120" />
              <el-table-column property="bus.number" :label="$t('bus')" width="120" />


              
 
            </el-table>
            <div class="d-flex justify-content-center mt-4">
              <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              :total="totalStudents"
              layout="prev, pager, next"
              background
            />
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
  title="Confirm Deletion"
  v-model="deleteStudentDialogVisible"
  width="30%"
  :before-close="handleDeleteDialogClose"
   custom-class="custom-dialog"
>
  <div class="dialog-content">
    <el-icon style="font-size: 28px; margin-right: 10px;">
      <WarningFilled />
    </el-icon>
    <span class="dialog-message">Are you sure you want to delete this student?</span>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="deleteStudentDialogVisible = false">Cancel</el-button>
    <el-button type="danger" @click="confirmDeleteStudent">Confirm</el-button>
  </span>
</el-dialog>


    <el-dialog v-model="dialogVisible" :title="$t('addStudent')" width="800">
      <span>Please fill out the form below</span>
      <el-steps :active="activeStep" finish-status="success">
        <el-step :title="$t('personalDetails')"></el-step>
        <el-step :title="$t('parentInformation')"></el-step>
        <el-step :title="$t('classAssignment')"></el-step>
        <el-step :title="$t('busAssignment')"></el-step>
        <!-- <el-step title="Subscription Fees"></el-step> -->
      </el-steps>
      <div v-if="activeStep === 0">
        <el-form :model="form">
          <el-form-item :label="$t('name')" :rules="[{ required: true, message: 'Please enter name', trigger: 'blur' }]">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('dateOfBirth')" :rules="[{ required: true, message: 'Please select date of birth', trigger: 'blur' }]">
            <el-date-picker v-model="form.date_of_birth" type="date" placeholder="Pick a date"></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('address')" :rules="[{ required: true, message: 'Please enter address', trigger: 'blur' }]">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item :label="$t('year')" :rules="[{ required: false, message: 'Please enter year', trigger: 'blur' }]">
            <el-input v-model="form.year"></el-input>
          </el-form-item>
          <el-form-item :label="$t('passportNumber')" :rules="[{ required: false, message: 'Please enter passport_number', trigger: 'blur' }]">
            <el-input v-model="form.passport_num"></el-input>
          </el-form-item>
          <el-form-item :label="$t('gotBooks?')" :rules="[{ required: true, message: 'Please select if the student has books', trigger: 'change' }]">
            <el-select v-model="form.has_books" placeholder="Select">
              <el-option label="Yes" value="yes"></el-option>
              <el-option label="No" value="no"></el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item :label="$t('nationalNumber')" :rules="[{ required: true, message: 'Please enter National Number', trigger: 'blur' }]">
            <el-input v-model="form.national_number"></el-input>
          </el-form-item>


          <el-form-item :label="$t('photo')" :rules="[{ required: true, message: 'Please enter photo', trigger: 'blur' }]">
            <el-upload
            class="upload-demo"
            drag
            :before-upload="(file) => beforePhotoUpload(file)"
            :on-change="handlePhotoChange"
            :auto-upload="false"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
            <template #tip>
              <div class="el-upload__tip">jpg/png files with a size less than 500kb</div>
            </template>
          </el-upload>
          
          
      </el-form-item>
        </el-form>
      </div>
      <div v-if="activeStep === 1">
        <el-form :model="form">
          <el-form-item :label="$t('parent')" :rules="[{ required: true, message: 'Please select parent', trigger: 'change' }]">
            <el-select v-model="form.parent_id" placeholder="Select Parent">
              <el-option v-for="parent in parents" :key="parent.id" :label="`${parent.first_name} ${parent.last_name}`" :value="parent.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeStep === 2">
        <el-form :model="form">
          <el-form-item :label="$t('class')" :rules="[{ required: true, message: 'Please select class', trigger: 'change' }]">
            <el-select v-model="form.class_id" @change="fetchSections" placeholder="Select Class">
              <el-option v-for="schoolClass in classes" :key="schoolClass.id" :label="schoolClass.name" :value="schoolClass.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('section')" :rules="[{ required: true, message: 'Please select section', trigger: 'change' }]">
            <el-select v-model="form.section_id" placeholder="Select Section">
              <el-option v-for="section in sections" :key="section.id" :label="section.name" :value="section.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeStep === 3">
        <el-form :model="form">
          <el-form-item :label="$t('bus')" :rules="[{ required: true, message: 'Please select bus', trigger: 'change' }]">
            <el-select v-model="form.bus_id" placeholder="Select Bus">
              <el-option v-for="bus in buses" :key="bus.id" :value="bus.id" :label="bus.number"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      
      <div class="step-actions">
        <el-button v-if="activeStep > 0" @click="prevStep">{{$t('previous')}}</el-button>
        <el-button v-if="activeStep < 3" type="primary" @click="nextStep">{{$t('next')}}</el-button>
        <el-button v-if="activeStep === 3" type="success" @click="submitForm">{{$t('submit')}}</el-button>
      </div>
    </el-dialog>


    


    <!-- Edit Student -->
    <el-dialog v-model="isVisibleEditStudentDialog" :title="$t('editStudent')" width="800">
      <el-steps :active="activeStep" finish-status="success">
        <el-step :title="$t('personalDetails')"></el-step>
        <el-step :title="$t('parentInformation')"></el-step>
        <el-step :title="$t('classAssignment')"></el-step>
        <el-step :title="$t('busAssignment')"></el-step>
      </el-steps>
  
      <!-- Step 0: Personal Details -->
      <div v-if="activeStep === 0">
        <el-form :model="form">
          <el-form-item :label="$t('name')" :rules="[{ required: true, message: 'Please enter name', trigger: 'blur' }]">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('dateOfBirth')" :rules="[{ required: true, message: 'Please select date of birth', trigger: 'blur' }]">
            <el-date-picker v-model="form.date_of_birth" type="date" placeholder="Pick a date"></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('address')" :rules="[{ required: true, message: 'Please enter address', trigger: 'blur' }]">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item :label="$t('year')">
            <el-input v-model="form.year"></el-input>
          </el-form-item>
          <el-form-item :label="$t('passportNumber')">
            <el-input v-model="form.passport_num"></el-input>
          </el-form-item>
          <el-form-item :label="$t('gotBooks?')" :rules="[{ required: true, message: 'Please select if the student has books', trigger: 'change' }]">
            <el-select v-model="form.has_books" placeholder="Select">
              <el-option label="Yes" value="yes"></el-option>
              <el-option label="No" value="no"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('nationalNumber')" :rules="[{ required: true, message: 'Please enter National Number', trigger: 'blur' }]">
            <el-input v-model="form.national_number"></el-input>
          </el-form-item>
          <el-form-item :label="$t('photo')" :rules="[{ required: true, message: 'Please enter photo', trigger: 'blur' }]">
            <el-upload
              class="upload-demo"
              drag
              :before-upload="(file) => beforePhotoUpload(file)"
              :on-change="handlePhotoChange"
              :auto-upload="false"
            >
              <el-icon class="el-icon--upload"><upload-filled /></el-icon>
              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
              <template #tip>
                <div class="el-upload__tip">jpg/png files with a size less than 500kb</div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
  
      <!-- Step 1: Parent Information -->
      <div v-if="activeStep === 1">
        <el-form :model="form">
          <el-form-item :label="$t('parent')" :rules="[{ required: true, message: 'Please select parent', trigger: 'change' }]">
            <el-select v-model="form.parent_id" placeholder="Select Parent">
              <el-option v-for="parent in parents" :key="parent.id" :label="`${parent.first_name} ${parent.last_name}`" :value="parent.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
  
      <!-- Step 2: Class Assignment -->
      <div v-if="activeStep === 2">
        <el-form :model="form">
          <el-form-item :label="$t('class')" :rules="[{ required: true, message: 'Please select class', trigger: 'change' }]">
            <el-select v-model="form.class_id" @change="fetchSections" placeholder="Select Class">
              <el-option v-for="schoolClass in classes" :key="schoolClass.id" :label="schoolClass.name" :value="schoolClass.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('section')" :rules="[{ required: true, message: 'Please select section', trigger: 'change' }]">
            <el-select v-model="form.section_id" placeholder="Select Section">
              <el-option v-for="section in sections" :key="section.id" :label="section.name" :value="section.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
  
      <!-- Step 3: Bus Assignment -->
      <div v-if="activeStep === 3">
        <el-form :model="form">
          <el-form-item :label="$t('bus')" :rules="[{ required: true, message: 'Please select bus', trigger: 'change' }]">
            <el-select v-model="form.bus_id" placeholder="Select Bus">
              <el-option v-for="bus in buses" :key="bus.id" :value="bus.id" :label="bus.number"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
  
      <div class="step-actions">
        <el-button v-if="activeStep > 0" @click="prevStep">{{$t('previous')}}</el-button>
        <el-button v-if="activeStep < 3" type="primary" @click="nextStep">{{$t('next')}}</el-button>
        <el-button v-if="activeStep === 3" type="success" @click="submitEdit">{{$t('submit')}}</el-button>
      </div>
    </el-dialog>
    <!-- Edit Student -->

    <el-dialog v-model="showDialogVisible" title="Student Details" width="800">
      <div v-if="selectedStudent">
        <p><strong>Name:</strong> {{ selectedStudent.name }}</p>
        <p><strong>Date of Birth:</strong> {{ selectedStudent.date_of_birth }}</p>
        <p><strong>Address:</strong> {{ selectedStudent.address }}</p>
        <p><strong>Year:</strong> {{ selectedStudent.year }}</p>
        <p><strong>Passport number:</strong> {{ selectedStudent.passport_num }}</p>
        <p><strong>Got Books :</strong> {{ selectedStudent.has_books }}</p>
        <p><strong>National Number:</strong> {{ selectedStudent.national_number }}</p>
        <p><strong>Parent:</strong> {{ selectedStudent.parent?.first_name ?? 'N/A' }} {{ selectedStudent.parent?.last_name ?? '' }}</p>
        <p><strong>Class:</strong> {{ selectedStudent.class?.name ?? 'N/A' }}</p>
        <p><strong>Bus:</strong> {{ selectedStudent.bus?.number ?? 'N/A' }}</p>
        <p><strong>Image Student:</strong>                   <img :src="`${endpointImage}/storage/${selectedStudent.photo}`" 
          alt="Meal Image" 
          style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover; overflow: hidden;" />
        </p>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { ElMessage } from 'element-plus';
import { Document, Printer } from '@element-plus/icons-vue';
import axios from 'axios';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';
// import logoWater from '@/assets/img/logo-school-one.png';
// import TajawalBold from "@/assets/fonts/Tajawal-Bold.ttf";
import AmiriRegular from "@/assets/fonts/Amiri-Regular.ttf";
import logoWater from "@/assets/img/logo-school-one.png";
import { mapActions,mapGetters } from "vuex";
import Echo from '../../echo'

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Tajawal:{
      normal: 'Tajawal-Bold.ttf',
      bold: 'Tajawal-Bold.ttf',
    },

  Amiri: {
    normal: AmiriRegular,
    bold: AmiriRegular,
    italics: AmiriRegular,
    bolditalics: AmiriRegular,
  },
};

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token'); // Get token from local storage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default {
  name: "Students",
  components: {
    Document,
    Printer,
  },
  data() {
    return {
      deleteStudentDialogVisible: false,  // For controlling the dialog visibility
      isVisibleEditStudentDialog: false,  // For controlling the dialog visibility
      studentIdToDelete: null,    
      activeStep: 0,
      dialogVisible: false,
      showDialogVisible: false,
      endpointImage:'https://sys.almasa-sch.ly',
      form: {
        id: null,
        name: '',
        date_of_birth: '',
        address: '',
        year: '',
        passport_num: '',
        has_books: '',
        national_number:'',
        parent_id: '',
        class_id: '',
        section_id: '',
        registration_fee: '',
        bus_id: '',
        books_fee: '',
        uniform_fee: '',
        transportation_fee: '',
        food_fee:'',
        photo:'',
        tuition_fee: '',
        treasury_id: null,
      },
      treasuries: [],
      searchQuery: '',
      students: [],
      parents: [],
      classes: [],
      sections: [],
      buses: [],
      subscriptionFees: {
        registration: [],
        books: [],
        uniform: [],
        transportation_food: [],
        tuition: [],
      },
      currentPage: 1,
      pageSize: 10,
      totalStudents: 0
    };
  },
  methods: 
  {
    formatDate(date) {
            const d = new Date(date);
            return d.toISOString().split('T')[0]; // This returns the date in 'YYYY-MM-DD' format
        },

        async submitEdit() {
  try {
    if (!this.form.id) {
      this.$message.error('No student selected for updating');
      return;
    }

    // Prepare FormData object for multipart/form-data
    const formData = new FormData();
    formData.append('id', this.form.id);
    formData.append('name', this.form.name || '');
    formData.append('gender', this.form.gender || '');
    formData.append('date_of_birth', this.formatDate(this.form.date_of_birth) || '');
    formData.append('grade_id', this.form.grade_id || '');
    formData.append('section_id', this.form.section_id || '');
    formData.append('parent_id', this.form.parent_id || '');
    formData.append('class_id', this.form.class_id || '');
    formData.append('bus_id', this.form.bus_id || '');
    formData.append('national_number', this.form.national_number || '');
    formData.append('passport_num', this.form.passport_num || '');
    formData.append('phone_number', this.form.phone_number || '');
    formData.append('address', this.form.address || '');
    formData.append('year', this.form.year || '');
    formData.append('has_books', this.form.has_books || '');

    // Add the photo file if selected
    if (this.form.photo) {
      formData.append('photo', this.form.photo);
    }

    // Send the PUT request to update the student
    await api.post(`/students/${this.form.id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    this.$message.success('Student updated successfully');
    this.resetForm();
    this.fetchStudents();
    this.isVisibleEditStudentDialog = false; // Close the dialog
  } catch (error) {
    console.error('Error submitting form:', error);
    this.$message.error('Error updating student');
  }
},
    beforePhotoUpload(file) {
    const isValidType = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt500kb = file.size / 1024 < 500;
    if (!isValidType) {
      ElMessage.error('Photo must be in jpg or png format');
    }
    if (!isLt500kb) {
      ElMessage.error('Photo size must be less than 500kb');
    }
    return isValidType && isLt500kb;
  },
  handlePhotoChange(file) {
    this.form.photo = file.raw;  // Store the actual file object
},

    storeNotification(notification) {
        // Retrieve existing notifications from local storage
        const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
        notifications.push(notification);

        // Save updated notifications back to local storage
        localStorage.setItem('notifications', JSON.stringify(notifications));
    },

    // Load notifications from local storage
    loadNotifications() {
        const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
        // Set the notifications in your Vue component's state or display them
        this.notifications = notifications; // Assuming you have a data property called notifications
    },

    // Add a method to clear notifications if needed
    clearNotifications() {
        localStorage.removeItem('notifications');
        this.notifications = []; // Clear local state
    },
    async fetchPrintStudents() {
      try {
        const response = await api.get('/students-print-report');
        const students = response.data;

        // Process the students data for PDF generation
        this.printStudents(students);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    },
    async updateStudentGender(studentId, gender) {
  try {
    // Send the PUT request to update the gender
    const response = await api.put(`/students/${studentId}/update-gender`, {
      gender: gender // Pass gender correctly
    });
    console.log('Gender updated successfully:', response.data);
  } catch (error) {
    console.error('Error updating gender:', error.response.data);
  }
},
reverseArabicText(text) {
  return text.split('').reverse().join('');
},
printStudents(students) {
  // Create a new window
  const printWindow = window.open('', '_blank');

  // Create the header with a title, date, and logo placeholder
  const headerHtml = `
    <div style="text-align: center; padding: 20px;">
      <h1 style="color: blue;">Almasa School</h1>
      <p style="font-size: 14px;">Date: ${new Date().toLocaleDateString()}</p>
      <img src="${logoWater}" alt="Logo" style="max-width: 100px;" />
    </div>
  `;

  // Create the main content with student details
  let studentsHtml = '<div style="padding: 20px; direction: rtl;">'; // Right to left direction for Arabic content
  students.forEach(student => {
    studentsHtml += `
      <h2 style="font-size: 16px;">إسم الطالب / ${student.name} ${student.parent.first_name} ${student.parent.last_name} </h2>
  
      <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
        <tr>
             <td style="border: 1px solid black; padding: 8px;">رقم ملف الطالب</td>
          <td style="border: 1px solid black; padding: 8px;">${student.id}</td>
       
        </tr>
        <tr>
              <td style="border: 1px solid black; padding: 8px;">الفصل</td>
          <td style="border: 1px solid black; padding: 8px;">${student.class && student.class.name ? student.class.name : 'N/A'}</td>
      
        </tr>
        <tr>
          <td colspan="2" style="border: 1px solid black; padding: 8px;">
            <hr style="border: 1px dashed black;" />
          </td>
        </tr>
      </table>
    `;
  });
  studentsHtml += '</div>';

  // Combine header and students content
  printWindow.document.write(`
    <html>
      <head>
        <title>Student Report</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          h1 {
            margin: 0;
          }
          table {
            width: 100%;
          }
        </style>
      </head>
      <body>
        ${headerHtml}
        ${studentsHtml}
      </body>
    </html>
  `);

  // Close the document and print
  printWindow.document.close();
  printWindow.print();
},
//     generatePDF(students) {
//   pdfMake.fonts = {
//     Tajawal:{
//       normal: 'Tajawal-Bold.ttf',
//       bold: 'Tajawal-Bold.ttf',
//     },
//     Amiri: {
//       normal: 'Amiri-Regular.ttf',
//       bold: 'Amiri-Regular.ttf',
//     },
//   };

//   const docDefinition = {
//     pageMargins: [40, 80, 40, 60], // Set margins for better layout
//     defaultStyle: {
//       font: 'Tajawal',
//       alignment: 'right',
//     },
//     header: [
 
      
//     {
//       columns: [
        
        
//         {
//           text: 'Almasa School',
//           alignment: 'center',
//           color: 'black',
//           fontSize: 18,
//           bold: true,
//           margin: [0, 15, 0, 0],
//         },
//         {
//           text: `Date: ${new Date().toLocaleDateString()}`,
//           alignment: 'right',
//           fontSize: 12,
//           margin: [0, 15, 10, 0], // Adjust date margin as needed
//         }
//       ],
//       margin: [20, 10, 20, 0]
//     },
   
//     ],
//     content: [],
//     styles: {
//       header: {
//         fontSize: 24,
//         bold: true,
//         color: 'blue',
//         alignment: 'center',
//         margin: [0, 20],
//       },
//       subheader: {
//         fontSize: 16,
//         bold: true,
//         margin: [0, 10, 0, 5],
//         alignment: 'right',
//       },
//       tableHeader: {
//         bold: true,
//         fontSize: 12,
//         color: 'black',
//         alignment: 'right',
//         direction: 'rtl' ,
      
//       },
//       table: {
//         margin: [0, 5, 0, 15],
//       },
//     },
//     footer: (currentPage, pageCount) => ({
//       text: `Page ${currentPage} of ${pageCount}`,
//       alignment: 'center',
//       margin: [0, 10],
//     }),

//   };

//   // Title


//   // Iterate through students
//   Object.values(students).forEach(student => {
//     const parent = student.parent || {};
//     const financialDocs = student.financial_documents || [];

//     docDefinition.content.push({
//       text: `${student.name} : الطالب إسم`,
//       style: 'subheader',
//       alignment: 'right',
//     rtl: true
//     });

//     // Student details table
//     docDefinition.content.push({
//       table: {
//         widths: ['*', '*'],
//         body: [
//           [ 
//           student.id, 
//           { text: 'الطالب ملف رقم', style: 'tableHeader', alignment: 'right', rtl: true } 
//         ],
//         [
//               student.class && student.class.name ? student.class.name : 'N/A',
//               { text: 'الفصل', style: 'tableHeader', alignment: 'right',direction: 'rtl'  }
//             ],

//           // [ student.address, { text: 'العنوان', style: 'tableHeader' }],
//           // [ student.national_number, { text: 'الوطني الرقم', style: 'tableHeader' }],
         
//           // [ student.passport_num, { text: 'الجواز رقم', style: 'tableHeader' }],
//           // [ student.has_books === 'yes' ? 'نعم' : 'لا', { text: '? الكتب إستلم', style: 'tableHeader' }],
//           // [ `${parent.first_name || ''} ${parent.last_name || ''}`, { text: 'الأمر ولي اسم', style: 'tableHeader' }],
//           // [ parent.phone_number_one, { text: 'الهاتف رقم', style: 'tableHeader' }],
//         ],
//       },
//       style: 'table',
//     });

//     // Financial documents
//     // if (financialDocs.length) {
//     //   docDefinition.content.push({
//     //     text: 'المالية المستندات:',
//     //     style: 'subheader',
//     //   });

//     //   financialDocs.forEach(doc => {
//     //     docDefinition.content.push({
//     //       table: {
//     //         widths: ['*', '*'],
//     //         body: [
//     //           [ doc.receipt_number, { text: 'الإيصال رقم', style: 'tableHeader' }],
//     //           [ doc.total_amount, { text: 'الكلي المبلغ', style: 'tableHeader' }],
//     //           [ doc.discount, { text: 'الخصم', style: 'tableHeader' }],
//     //           [ doc.final_amount, { text: 'النهائي المبلغ', style: 'tableHeader' }],
//     //           [ doc.payment_method, { text: 'الدفع طريقة', style: 'tableHeader' }],
//     //         ],
//     //       },
//     //       style: 'table',
//     //     });
//     //   });
//     // }

//     // Divider line between students
//     docDefinition.content.push({
//       text: '------------------------------------------',
//       margin: [0, 10],
//     });
//   });

//   // Generate PDF with watermark
//   pdfMake.createPdf(docDefinition).download('student_report.pdf');
// },


    operationCellClass() {
      alert("s")
      return 'operation-cell-align-right';
    },
    rowClassName({ row }) {
    
      // Apply a specific class for rows with a certain ID
      return row.id ? 'highlight-id-row' : '';
    },
    highlightIdCell({ row, column }) {

  if (column.property === 'id') {
    return 'highlight-id-cell';
  }
  return '';
},
    async fetchTreasuries() {
        try {
          const response = await api.get('/treasuries');
          this.treasuries = response.data.filter(treasury => treasury.type === 'manual');
        } catch (error) {
          this.$message.error('Failed to fetch treasuries');
        }
      },
    async fetchStudents() {
      try {
        const response = await api.get('/students', {
          params: {
            search: this.searchQuery,
            per_page: this.pageSize,
            page: this.currentPage
          }
        });
        this.students = response.data.data;
        this.totalStudents = response.data.total;
      } catch (error) {
        ElMessage.error('Failed to fetch students');
      }
    },
    async fetchParents() {
      try {
        const response = await api.get('/parent-pushed-student');
        this.parents = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch parents');
      }
    },
    async fetchClasses() {
      try {
        const response = await api.get('/classes');
        this.classes = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch classes');
      }
    },
    async fetchSections() {
      try {
        const response = await api.get('/sections', { params: { class_id: this.form.class_id } });
        this.sections = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch sections');
      }
    },
    async fetchBuses() {
      try {
        const response = await api.get('/buses');
        this.buses = response.data;
      } catch (error) {
        ElMessage.error('Failed to fetch buses');
      }
    },
    async fetchSubscriptionFees() {
  try {
    const response = await api.get('/subscription-fees');
    const fees = response.data;
    this.subscriptionFees = {
      registration: fees.filter(fee => fee.category === 'Registration Fees'),
      books: fees.filter(fee => fee.category === 'Books'),
      uniform: fees.filter(fee => fee.category === 'Uniform'),
      transportation: fees.filter(fee => fee.category === 'Transportation' && fee.sub_category === 'Bus Service'),
      food: fees.filter(fee => fee.category === 'Food' && fee.sub_category === 'Meals'),
      tuition: fees.filter(fee => fee.category === 'Tuition Fees')
    };
  } catch (error) {
    ElMessage.error('Failed to fetch subscription fees');
  }
},
async submitForm() {
  if (this.form.date_of_birth instanceof Date) {
    this.form.date_of_birth = this.form.date_of_birth.toISOString().split('T')[0];
  }

  const formData = new FormData();
  for (const key in this.form) {
    if (this.form.hasOwnProperty(key) && this.form[key] !== null) {
      formData.append(key, this.form[key]);
    }
  }

  if (this.form.photo) {
    formData.append('photo', this.form.photo);
  }

  try {
    let response;
    if (this.form.id) {
      // Update student
      response = await api.patch(`/students/${this.form.id}`, formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });
      ElMessage.success('Student updated successfully');
    } else {
      // Create new student
      response = await api.post('/students', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      ElMessage.success('Student added successfully');
    }

    const studentId = response.data.id;

    // Optional: Handle subscription fees if necessary
    const subscriptionFeesPayload = [
      this.form.registration_fee ? { subscription_fee_id: this.form.registration_fee, amount: this.subscriptionFees.registration.find(fee => fee.id === this.form.registration_fee).amount } : null,
      this.form.books_fee ? { subscription_fee_id: this.form.books_fee, amount: this.subscriptionFees.books.find(fee => fee.id === this.form.books_fee).amount } : null,
      this.form.uniform_fee ? { subscription_fee_id: this.form.uniform_fee, amount: this.subscriptionFees.uniform.find(fee => fee.id === this.form.uniform_fee).amount } : null,
      this.form.transportation_fee ? { subscription_fee_id: this.form.transportation_fee, amount: this.subscriptionFees.transportation.find(fee => fee.id === this.form.transportation_fee).amount } : null,
      this.form.food_fee ? { subscription_fee_id: this.form.food_fee, amount: this.subscriptionFees.food.find(fee => fee.id === this.form.food_fee).amount } : null,
      this.form.tuition_fee ? { subscription_fee_id: this.form.tuition_fee, amount: this.subscriptionFees.tuition.find(fee => fee.id === this.form.tuition_fee).amount } : null
    ].filter(fee => fee !== null);

    const payload = {
      subscriptionFees: subscriptionFeesPayload,
      treasury_id: this.form.treasury_id,
    };

    if (subscriptionFeesPayload.length > 0) {
      await api.post(`/students/${studentId}/subscription-fees`, payload);
    }

    this.resetForm();
    this.fetchStudents();
  } catch (error) {
    if (error.response && error.response.data.errors) {
      const errors = error.response.data.errors;
      for (const field in errors) {
        if (errors.hasOwnProperty(field)) {
          errors[field].forEach(errorMessage => {
            ElMessage.error(errorMessage);
          });
        }
      }
    } else {
      ElMessage.error('Failed to save student');
    }
  }
}

,

async deleteStudent(id) {
    this.studentIdToDelete = id;
    this.deleteStudentDialogVisible = true;  // Open the confirmation dialog
  },
  async confirmDeleteStudent() {
    try {
      await api.delete(`/students/${this.studentIdToDelete}`);
      ElMessage.success('Student deleted successfully');
      this.fetchStudents();  // Refresh the student list after deletion
    } catch (error) {
      ElMessage.error('Failed to delete student');
    }
    this.deleteStudentDialogVisible = false;  // Close the dialog
    this.studentIdToDelete = null;  // Reset the ID after deletion
  },
  handleDeleteDialogClose() {
    this.studentIdToDelete = null;  // Reset ID if the dialog is closed without confirmation
  },
    async showStudent(student) {
      try {
        const response = await api.get(`/students/${student.id}`);
        this.selectedStudent = response.data;
        this.showDialogVisible = true;
      } catch (error) {
        this.$message.error('Failed to fetch student details');
      }
    },
    async printStudent(student) {
      try {
        const response = await api.get(`/students/${student.id}`);
        const studentDetails = response.data;

        // Open a new page with the invoice
        const invoiceUrl = `/invoice.html?id=${student.id}`;
        window.open(invoiceUrl, '_blank');
      } catch (error) {
        console.error('Error fetching student details for printing:', error);
        this.$message.error('Failed to fetch student details for printing');
      }
    },
    editStudent(student) {
      this.form = {
        ...student,
        date_of_birth: new Date(student.date_of_birth), // Convert date to Date object if needed
    };
      this.isVisibleEditStudentDialog = true;
    },
    resetForm() {
      this.form = {
        id: null,
        name: '',
        date_of_birth: '',
        address: '',
        year: '',
        passport_num: '',
        has_books: '',
        national_number: '',
        parent_id: '',
        class_id: '',
        section_id: '',
        registration_fee: '',
        bus_id: '',
        books_fee: '',
        uniform_fee: '',
        transportation_fee: '',
        food_fee:'',
        tuition_fee: ''
      };
      this.dialogVisible = false;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchStudents();
    },
    handleSearch() {
      this.currentPage = 1; // Reset to first page on new search
      this.fetchStudents();
    },
    prevStep() {
      this.activeStep--;
    },
    nextStep() {
      this.activeStep++;
    }
  },
  computed: {
    pagedStudents() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredStudents.slice(startIndex, endIndex);
    },
    filteredStudents() {
      const query = this.searchQuery.toLowerCase();
      return this.students.filter(student => {
        return (
          student.name.toLowerCase().includes(query) ||
          (student.bus && student.bus.number.toLowerCase().includes(query))
        );
      });
    },
    isDarkMode() {
      // Access the `isDarkMode` state from Vuex or another state management system
      return this.$store.state.isDarkMode;
    },
    ...mapGetters(['isDarkMode']),
  },
  mounted() {
    console.log('Component mounted'); // Debug log
    this.fetchTreasuries();
    this.fetchStudents();
    this.fetchParents();
    this.fetchClasses();
    this.fetchBuses();
    this.fetchSubscriptionFees();

    const userId = localStorage.getItem('userId');
    const username = localStorage.getItem('username');
    console.log(userId); // Check if the user data is available

    if (userId) {
        // Store the channel reference for cleanup
        this.notificationChannel = Echo.private(`App.Models.User.${userId}`)
            .notification((notification) => {
                console.log('New Notification:', notification);

                // Get current date and time
                const currentDateTime = new Date();
                const date = currentDateTime.toLocaleDateString(); 
                const time = currentDateTime.toLocaleTimeString(); 

                let title = '';
                let type = ''; // Default to empty

                // Handle student added notification
                if (notification.message === 'A new student has been added.') {
                    title = `
                    تم إضافة طالب للنظام من قبل ${username}
                    إسم الطالب : ${notification.student_name}
                    رقم ملف الطالب: ${notification.student_id}
                    التاريخ: ${date}
                    الوقت: ${time}
                    `;

                    type = 'success';
                }

                // Handle student deleted notification
                if (notification.message === 'A student has been deleted.') {
                    title = `
                    تم حذف طالب من النظام من قبل ${username}
                    إسم الطالب : ${notification.student_name}
                    رقم ملف الطالب: ${notification.student_id}
                    التاريخ: ${date}
                    الوقت: ${time}
                    `;

                    type = 'warning';
                }

                // Only trigger notification if title is set
                if (title) {
                    this.$notify({
                        title: title,
                        message: notification.message,
                        type: type // Use the determined type
                    });

                    // Store notification in local storage
                    this.storeNotification({ title: title });
                }
            });
    } else {
        console.error('User ID is null, cannot subscribe to notifications');
    }

    // Load previously stored notifications
    this.loadNotifications();
},

beforeDestroy() {
  Echo.leave(`App.Models.User.${localStorage.getItem('userId')}`);
    if (this.notificationChannel) {
        this.notificationChannel.unsubscribed(); // Clean up the notification channel
    }
},


  watch: {
    searchQuery: 'handleSearch'
  }
};
</script>




<style scoped>

.el-table >>> .highlight-id-cell {
  background-color: #063f5a !important; /* Increased specificity */
  color:white;
  text-align: center;
  font: 800;
  font-size: 20px;
}

.highlight-id-row {
  background-color: #003c96; /* light background color */
}

.el-table >>> .operation-cell-align-right {
  text-align: right !important;
}
.el-table >>> .el-table__cell {
  text-align: right !important;
}
.operation-buttons {
  display: flex;
  justify-content: flex-end;
}

.custom-dialog {
  transition: all 0.3s ease;
}

.dialog-content {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.dialog-message {
  margin-left: 10px;
  font-size: 16px;
}

.dialog-footer {
  display: flex;
  justify-content: space-evenly;
}



.warning-icon {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%, 100% {
      transform: translateX(0);
  }
  25% {
      transform: translateX(-4px);
  }
  75% {
      transform: translateX(4px);
  }
}

.bg-header-table {
    
}

.search-input {
    width: 300px;
    margin-right: 10px;
}

.step-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>
