<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card my-4">
                    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                        <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
                            <div class="d-flex justify-content-around">
                                <h6 class="text-white text-capitalize ps-3">Parents Records</h6>
                                <el-input
                                style="width: 200px;"
                                    v-model="searchQuery"
                                    placeholder="Search by first name, last name, phone number, city, or national number"
                                    @input="handleSearch"
                                />
                                <el-button @click="dialogVisible = true" type="success" circle class="mr-5">
                                    <el-icon style="vertical-align: middle">
                                        <Document />
                                    </el-icon>
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table :data="allParents" style="width: 100%" :cell-class-name="highlightIdCell">
                            <el-table-column type="expand" label="Operations" width="100">
                                <template #default="scope">
                                    <el-button @click="showParent(scope.row)" type="primary" size="mini">Show</el-button>
                                    <el-button @click="editParent(scope.row)" type="warning" size="mini">Edit</el-button>
                                    <el-button @click="confirmDelete(scope.row.id)" type="danger" size="mini">Delete</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column property="id" label="FILE NUMBER OF PARENT" width="200" />
                            <el-table-column property="first_name" label="First Name" width="120" />
                            <el-table-column property="last_name" label="Last Name" width="120" />
                            <el-table-column property="phone_number_one" label="Phone Number One" width="120" />
                            <el-table-column property="phone_number_two" label="Phone Number Two" width="120" />
                            <el-table-column property="city" label="City" width="120" />
                            <el-table-column property="address" label="Address" />
                            <el-table-column property="passport_num" label="Passport Number" width="120" />
                            <el-table-column property="national_number" label="National Number"  width="120"/>
                        </el-table>

                        <div class="d-flex justify-content-center mt-4">
                            <el-pagination
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                                :page-size="pageSize"
                                :total="totalParents"
                                layout="prev, pager, next">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Confirmation Dialog -->
        <el-dialog title="Confirm Deletion" v-model="deleteDialogVisible" width="30%" :before-close="handleClose">
            <div class="dialog-content">
                <el-icon class="warning-icon">
                    <WarningFilled style="color: #f56c6c; font-size: 24px;" />
                </el-icon>
                <span class="dialog-message">Are you sure you want to delete this parent?</span>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="deleteDialogVisible = false" type="info">Cancel</el-button>
                <el-button class="confirm-btn" type="danger" @click="deleteParentConfirmed">Confirm</el-button>
            </div>
        </el-dialog>

        <el-dialog v-model="dialogVisible" title="Add/Edit Parent" width="830">
            <span>Please fill out the form below</span>
            <el-form :model="form" ref="parentForm" label-width="120px">
              <div class="form-grid">
                <el-form-item label="First Name" :rules="[{ required: true, message: 'Please enter first name', trigger: 'blur' }]">
                  <el-input v-model="form.first_name"></el-input>
                </el-form-item>
                <el-form-item label="Last Name" :rules="[{ required: true, message: 'Please enter last name', trigger: 'blur' }]">
                  <el-input v-model="form.last_name"></el-input>
                </el-form-item>
      
                <el-form-item label="Phone Number One" :rules="[{ required: true, message: 'Please enter phone number one', trigger: 'blur' }]">
                  <el-input v-model="form.phone_number_one"></el-input>
                </el-form-item>
                <el-form-item label="Phone Number Two" :rules="[{ required: true, message: 'Please enter phone number two', trigger: 'blur' }]">
                  <el-input v-model="form.phone_number_two"></el-input>
                </el-form-item>
      
                <el-form-item label="City" :rules="[{ required: true, message: 'Please enter city', trigger: 'blur' }]">
                  <el-input v-model="form.city"></el-input>
                </el-form-item>
                <el-form-item label="Address" :rules="[{ required: true, message: 'Please enter address', trigger: 'blur' }]">
                  <el-input v-model="form.address"></el-input>
                </el-form-item>
      
                <el-form-item label="National Number" :rules="[{ required: false, message: 'Please enter National Number', trigger: 'blur' }]">
                  <el-input v-model="form.national_number"></el-input>
                </el-form-item>
                <el-form-item style="font-weight: 900 !important;" label="Passport Number" :rules="[{ required: false, message: 'Please enter Passport Number', trigger: 'blur' }]">
                  <el-input v-model="form.passport_num"></el-input>
                </el-form-item>
              </div>

              <hr />
      
              <div class="form-grid">
                <el-form-item label="ID Image">
                  <el-upload
                    action=""
                    list-type="picture-card"
                    :file-list="form.id_image_list"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-upload="beforeUploadIDImage">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item label="Passport Image">
                  <el-upload
                    action=""
                    list-type="picture-card"
                    :file-list="form.passport_image_list"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-upload="beforeUploadPassportImage">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
              </div>
            </el-form>
      
            <div class="step-actions">
              <el-button type="primary" @click="submitForm">Submit</el-button>
            </div>
          </el-dialog>
      
          <!-- Show Parent Dialog -->
          <el-dialog v-model="showDialogVisible" title="Parent Details" width="800">
            <div v-if="selectedParent" class="parent-info-grid">
              <div>
                <strong>First Name:</strong> {{ selectedParent.first_name }}
              </div>
              <div>
                <strong>Last Name:</strong> {{ selectedParent.last_name }}
              </div>
              <div>
                <strong>Phone Number One:</strong> {{ selectedParent.phone_number_one }}
              </div>
              <div>
                <strong>Phone Number Two:</strong> {{ selectedParent.phone_number_two }}
              </div>
              <div>
                <strong>City:</strong> {{ selectedParent.city }}
              </div>
              <div>
                <strong>Address:</strong> {{ selectedParent.address }}
              </div>
              <div>
                <strong>National Number:</strong> {{ selectedParent.national_number }}
              </div>
              <div>
                <strong>Passport Number:</strong> {{ selectedParent.passport_num }}
              </div>
      
              <div class="parent-images">
                <div>
                  <strong>ID Image:</strong>
                  <img :src="getImageUrl(selectedParent.id_image)" alt="ID Image" class="responsive-image">
                </div>
                <div>
                  <strong>Passport Image:</strong>
                  <img :src="getImageUrl(selectedParent.passport_image)" alt="Passport Image" class="responsive-image">
                </div>
              </div>
            </div>
          </el-dialog>
    </div>
</template>

<script>
import { Document } from '@element-plus/icons-vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import Echo from '../../echo';
const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token'); // Get token from local storage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


export default {
    name: "Parents",
    components: { Document },
    data() {
        return {
            dialogVisible: false,
            deleteDialogVisible: false,
            selectedParentId: null,
            showDialogVisible: false,
            selectedParent: null,
            form: {
                id: null,
                first_name: '',
                last_name: '',
                phone_number_one: '',
                phone_number_two: '',
                city: '',
                address: '',
                national_number: '',
                passport_num: '',
                id_image: '',
                id_image_list: [],
                passport_image: '',
                passport_image_list: [],
            },
            searchQuery: '',
            allParents: [],
            currentPage: 1,
            pageSize: 10,
            totalParents: 0,
        };
    },
    methods: {
        storeNotification(notification) {
        // Retrieve existing notifications from local storage
        const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
        notifications.push({
            title: notification.title,
            timestamp: new Date().toISOString() // Add a timestamp if you need it for sorting or other purposes
        });

        // Save updated notifications back to local storage
        localStorage.setItem('notifications', JSON.stringify(notifications));
    },

    // Load notifications from local storage
    loadNotifications() {
        const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
        // Set the notifications in your Vue component's state or display them
        this.notifications = notifications; // Assuming you have a data property called notifications
    },
        getImageUrl(imagePath) {
            return `http://localhost:8000/storage/${imagePath}`;
        },
        async fetchParents() {
            try {
                const response = await api.get('/parents', {
                    params: {
                        search: this.searchQuery,
                        per_page: this.pageSize,
                        page: this.currentPage,
                    },
                });
                this.allParents = response.data.data;
                this.totalParents = response.data.total;
            } catch (error) {
                ElMessage.error('Failed to fetch parents');
            }
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.fetchParents();
        },
        handleSearch() {
            this.currentPage = 1; // Reset to first page on new search
            this.fetchParents();
        },
        highlightIdCell({ row, column }) {

if (column.property === 'id') {
  return 'highlight-id-cell';
}
return '';
},
editParent(parent) {
            this.form = { ...parent };
            this.dialogVisible = true;
        },
        async submitForm() {
    try {
        if (this.form.id) {
            // Update existing parent
            await api.put(`/parents/${this.form.id}`, {
                first_name: this.form.first_name,
                last_name: this.form.last_name,
                phone_number_one: this.form.phone_number_one,
                phone_number_two: this.form.phone_number_two,
                city: this.form.city,
                address: this.form.address,
                national_number: this.form.national_number,
                passport_num: this.form.passport_num,
            });
            ElMessage.success('Parent updated successfully.');
        } else {
            // Create new parent
            await api.post('/parents', this.form);
            ElMessage.success('Parent created successfully.');
        }
        this.fetchParents();
        this.dialogVisible = false;
        this.resetForm();
    } catch (error) {
        if (error.response && error.response.data.errors) {
            // Display validation error messages
            const messages = Object.values(error.response.data.errors)
                .flat()
                .join(', ');
            ElMessage.error(messages);
        } else {
            console.error(error);
            ElMessage.error('Failed to save parent.');
        }
    }
},
        resetForm() {
            this.form = {
                id: null,
                first_name: '',
                last_name: '',
                phone_number_one: '',
                phone_number_two: '',
                city: '',
                address: '',
                national_number: '',
                passport_num:'',
                id_image: '',
                id_image_list: [],
                passport_image: '',
                passport_image_list: [],
            };
            this.dialogVisible = false;
        },

        async confirmDelete(id) {
            this.selectedParentId = id;
            this.deleteDialogVisible = true;
        },
        async deleteParentConfirmed() {
            try {
                await api.delete(`/parents/${this.selectedParentId}`);
                ElMessage.success('Parent deleted successfully');
                this.fetchParents();
            } catch (error) {
                ElMessage.error('Failed to delete parent');
            } finally {
                this.deleteDialogVisible = false;
            }
        },
        showParent(parent) {
            this.selectedParent = parent;
            this.showDialogVisible = true;
        },
        handleClose(done) {
            this.$confirm('Are you sure you want to close this dialog?')
                .then(() => {
                    done();
                })
                .catch(() => {});
        },
    },
    mounted() {
        this.fetchParents();
        const userId = localStorage.getItem('userId')
    const username = localStorage.getItem('username')
    console.log(userId);  // Check if the user data is available


if (userId) {
    Echo.private(`App.Models.User.${userId}`)
        .notification((notification) => {
            console.log('New Notification:', notification);
              // Get the current date and time
              const currentDateTime = new Date();
            const date = currentDateTime.toLocaleDateString(); // e.g., 25/09/2024
            const time = currentDateTime.toLocaleTimeString(); // e.g., 14:35:06

            const title = `
                تم إضافة ولي أمر للنظام من قبل ${username} 
                إسم ولي الأمر: ${notification.first_name} 
                رقم ملف ولي الأمر: ${notification.parent_id} 
                التاريخ: ${date} 
                الوقت: ${time}
            `;
            
            this.$notify({
                title: `
                تم إضافة ولي أمر للنظام من قبل ${username}
                إسم ولي الأمر : ${notification.first_name}
                رقم ملف ولي الأمر: ${notification.parent_id}
                `,
                message: notification.message,
                type: 'success'
            });
                // Store notification in local storage
                this.storeNotification({ title: title });
        });
} else {
    console.error('User ID is null, cannot subscribe to notifications');
}
this.loadNotifications();
    },
};
</script>



  <style scoped>
  .el-table >>> .highlight-id-cell {
    background-color: #063f5a !important; /* Increased specificity */
    color:white;
    text-align: center;
    font: 800;
    font-size: 20px;
  }
  .el-table >>> .operation-cell-align-right {
    text-align: right !important;
  }
  .el-table >>> .el-table__cell {
    text-align: right !important;
  }
  .operation-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .custom-dialog {
    transition: all 0.3s ease;
}

.dialog-content {
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.dialog-message {
    margin-left: 10px;
    font-size: 16px;
}

.dialog-footer {
    display: flex;
    justify-content: space-evenly;
}



.warning-icon {
    animation: shake 0.5s ease-in-out;
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-4px);
    }
    75% {
        transform: translateX(4px);
    }
}

  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .parent-info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  
  .parent-images {
    display: flex;
    justify-content: space-between;
  }
  
  .responsive-image {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .step-actions {
    text-align: right;
    margin-top: 20px;
  }
  .bg-header-table.dark-mode {
    background-image: linear-gradient(to right, #434343 0%, black 100%);
}

.search-input {
    width: 0px;
    margin-right: 10px;
}

.step-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.bg-header-table{
    
}


.parent-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.parent-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.detail-item {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
}

.parent-images {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.image-item {
    flex: 1;
    text-align: center;
}

.responsive-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}
</style>