<template lang="">
    <div>
      <el-card>
        <h3>Add Funds to Parent Wallet</h3>
        <el-form :model="walletForm" @submit.prevent="addFunds">
          <el-form-item label="Parent ID" prop="parent_id" :rules="[{ required: true, message: 'Please input Parent ID' }]">
            <el-input v-model="walletForm.parent_id" placeholder="Enter Parent ID" />
          </el-form-item>
  
          <el-form-item label="balance" prop="balance" :rules="[{ required: false, message: 'Please input balance' }]">
            <el-input v-model="walletForm.balance" placeholder="Enter balance" type="number" />
          </el-form-item>
  
          <el-button type="primary" @click="addFunds">Add Funds</el-button>
        </el-form>
  
        <el-alert v-if="message" :title="message" type="success" />
      </el-card>
    </div>
  </template>
  
  <script>

import axios from 'axios';
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
  });


  
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token'); // Get token from local storage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  export default {
    data() {
      return {
        walletForm: {
          parent_id: '',
          balance: '',
        },
        message: '',
      };
    },
    methods: {
      async addFunds() {
        try {
          const response = await api.post('/parent-wallet/add-funds', this.walletForm);
          this.message = response.data.message;
          // Reset the form
          this.walletForm.balance = '';
        } catch (error) {
          if (error.response && error.response.data) {
            this.message = error.response.data.message;
          } else {
            this.message = 'An error occurred. Please try again.';
          }
        }
      },
    },
  };
  </script>
  
  <style lang="">
  /* You can add your styles here */
  </style>
  