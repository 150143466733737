<template>
  <div
  style="height: 54%;"
    class="w-auto  collapse navbar-collapse max-height-vh-200 h-80"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      
      <li     class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="dashboard"
          :navText="$t('dashboard') "
      
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'moder'"  class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="users"
          :navText="$t('users') "
          
        >
          <template v-slot:icon>
            <i class="fa fa-user-circle"></i>
          </template>
        </sidenav-collapse>
      </li>
      <li  v-if="userType === 'admin' || userType === 'hr' || userType === 'moder' || userType === 'prin'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="departments"
          :navText="$t('departments')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li  v-if="userType === 'doctor' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="health-files"
          :navText="$t('healthFilesManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li  v-if="userType === 'doctor' || userType === 'prin' || userType === 'student_m' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="all-health-files"
          :navText="$t('archiveHealthFiles')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'prin' || userType === 'moder' " class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="buses"
          :navText="$t('busManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="school-schedule"
          navText="الجدول المدرسي"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li> -->
      <li v-if="userType === 'admin' || userType === 'hr' || userType === 'prin' || userType === 'student_m' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="teachers-type-subject"
          :navText="$t('subjectsManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'admin' || userType === 'student_m' || userType === 'moder' || userType === 'prin' || userType === 'hr'" class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="classes"
          :navText="$t('classesManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'prin' ||  userType === 'hr' || userType === 'student_m' || userType === 'moder' "  class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="classes-sections"
          :navText="$t('sectionsOfClassManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="userType === 'hr' || userType === 'prin' || userType === 'moder' || userType === 'financial'">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="employee-reports"
          :navText="$t('employeesReports')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'uknown'"  class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="exams"
          :navText="$t('examManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'admin' || userType === 'student_m' || userType === 'moder' || userType === 'hr' || userType === 'prin'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="students"
          :navText="$t('studentsManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'financial' || userType === 'hr' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="print-financial-document"
          :navText="$t('archiveFinancialDocument')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'financial' || userType === 'hr' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="financial-document"
          :navText="$t('financialManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li  class="nav-item" v-if="userType === 'student_m' || userType === 'hr' || userType === 'moder'" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="cards-students"
          :navText="$t('studentsCards')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li  class="nav-item" v-if="userType === 'moder'" >
        <sidenav-collapse
          url="activity-log"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="activity-log"
          :navText="$t('activityLog')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li  
      v-if="userType === 'financial' || userType === 'moder'"
      class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="subscription-fee"
          :navText="$t('subscriptionFees')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'financial' || userType === 'moder' || userType === 'student_m'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="student-attendances"
          :navText="$t('studentAttendances')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'financial' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="treasury-transactions"
          :navText="$t('treasuryTransactions')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'financial' || userType === 'moder' " class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="treasury"
          :navText="$t('treasury')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li  class="nav-item"  v-if="userType === 'unkown'">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="logs-checks"
          navText="الحضور والانصراف"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li><li  class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="verify-parent-identity"
          :navText="$t('dismissalSupervision')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'admin' || userType === 'student_m' || userType === 'prin' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="parents"
          :navText="$t('parentsManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'admin' || userType === 'financial' || userType === 'student_m' || userType === 'prin' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="send-emails"
          :navText="$t('sendEmails')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="!userType === 'financial'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="predictions"
          navText="تحليل بيانات الطلبة `ذكاء إصطناعي`"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li  class="nav-item" v-if="userType === 'hr' || userType === 'moder' || userType === 'financial'">
        <sidenav-collapse
          url="parent-wallet"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="parent-wallet"
          :navText="$t('parentWallet')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li  class="nav-item" v-if="userType === 'student_m' || userType === 'hr' || userType === 'prin' || userType === 'financial' || userType === 'moder'">
        <sidenav-collapse
          url="student-report"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="student-report"
          :navText="$t('studentsReports')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li  class="nav-item" >
        <sidenav-collapse
          url="kitchen"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="kitchen"
          :navText="$t('kitchenManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>

      <li  class="nav-item" >
        <sidenav-collapse
          url="kitchen-sales"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="kitchen-sales"
          :navText="$t('kitchenSales')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>

      <li v-if="userType === 'admin' || userType === 'hr' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="employees"
          :navText="$t('employeesManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="!userType === 'financial'" class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="summer-camp-add"
          navText="إدارة المخيم الصيفي"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="!userType === 'financial'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="summer-camp-data"
          navText="بيانات المخيم"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'financial' || userType === 'hr' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="manage-salaries"
          :navText="$t('salariesManagement')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'financial' || userType === 'hr' || userType === 'moder'" class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="absence-and-deduction"
          :navText="$t('absenceAndDeduction')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="userType === 'financial' || userType === 'hr' || userType === 'moder' " class="nav-item" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="salaries"
          :navText="$t('salaries')"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="!userType === 'admin'">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="tables"
          navText="Tables"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">table_view</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="!userType === 'admin'">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="billing"
          navText="Billing"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">receipt_long</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="!userType === 'admin'">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="rtl-page"
          navText="Rtl"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5"
              >format_textdirection_r_to_l</i
            >
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="!userType === 'admin'">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="notifications"
          navText="Notifications"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">notifications</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item" v-if="!userType === 'admin'">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white"
          :class="$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          ACCOUNT PAGES
        </h6>
      </li>
      <li class="nav-item" v-if="!userType === 'admin'">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="profile"
          navText="Profile"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">person</i>
          </template>
        </sidenav-collapse>
      </li>
      <li >
        <button @click="handleLogout" class="learn-more">
          <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
          </span>
          <span class="button-text">تسجيل الخروج</span>
        </button>
      </li>
      <li class="nav-item" v-if="!userType === 'admin'">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="sign-up"
          navText="SignUp"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">assignment</i>
          </template>
        </sidenav-collapse>
      </li>
    </ul>

  </div>
</template>
<script>
import axios from 'axios';
import SidenavCollapse from "./SidenavCollapse.vue";
import { mapGetters,mapMutations  } from 'vuex';
export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  methods:{
    ...mapMutations(['LOGOUT']),
    async handleLogout() {
      try {
        // Make a POST request to the logout endpoint
        await axios.post('https://sys.almasa-sch.ly/api/logout', {}, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        });

        // Clear local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_type');

        // Update Vuex state
        this.LOGOUT();

        // Redirect to SignIn page
        window.location.reload()
        this.$router.push({ name: 'SignIn' }).catch((error) => {
          console.error('Navigation error:', error);
        });
      } catch (error) {
        // Handle errors (e.g., show a notification)
        console.error('Logout error:', error);
      }
    }
  },
  components: {
    SidenavCollapse,
  },
  computed: {
    ...mapGetters(['userType']),
  },
};
</script>


<style scoped>
@font-face {
  font-family: 'DINNextLTArabic-Regular';
  src: url('../../../public/fonts/DINNextLTArabic-Regular.ttf') format('truetype');

}

ul,.nav-link-text {
font-family: DINNextLTArabic-Regular !important;
}

.router-link-exact-active {
  background-image: linear-gradient(195deg, #001462 0%, #0026f0 100%) !important;

}
/* From Uiverse.io by cssbuttons-io */ 
button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
 }
 
 button.learn-more {
  width: 12rem;
  height: auto;
 }
 
 button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
 }
 
 button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
 }
 
 button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
 }
 
 button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
 }
 
 button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
 }
 
 button:hover .circle {
  width: 100%;
 }
 
 button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
 }
 
 button:hover .button-text {
  color: #fff;
 }
</style>