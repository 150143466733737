// store/index.js
import { createStore } from "vuex";
import { login, logout, register } from '@/api';
import events from './modules/events';
import echoInstance from '@/echo'; // Import the Echo configuration from a dedicated file
export default createStore({
  modules: {
    events
  },
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: true,
    color: "success",
    language: localStorage.getItem("language") || "en", // Default to English if not set
    isAuthenticated: !!localStorage.getItem('access_token'),
    userType: localStorage.getItem('user_type') || '', 
    token: localStorage.getItem('access_token') || '',
    userId: localStorage.getItem('user_id') || null, // Store user ID in state
    username: localStorage.getItem('username') || null, // Store user ID in state
    notifications: [],
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    setDisplayVisibility(state, isVisible) {
      state.showNavbar = isVisible;
      state.showSidenav = isVisible;
      state.showFooter = isVisible;
    },
    setLanguage(state, language) {
      state.language = language;
      localStorage.setItem("language", language); // Store language in localStorage
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    color(state, payload) {
      state.color = payload;
    },
    SET_AUTH(state, { token, userType, userId,username }) {
      state.isAuthenticated = true;
      state.token = token;
      state.userType = userType;
      state.userId = userId;
      state.username = username;

      localStorage.setItem('access_token', token);
      localStorage.setItem('user_type', userType);
      localStorage.setItem('user_id', userId); // Store user ID in localStorage
      localStorage.setItem('username', username); // Store user ID in localStorage

      // Subscribe to notifications using Echo
      if (userId) {
        echoInstance.private(`App.Models.User.${userId}`)
          .notification((notification) => {
            console.log('New Notification:', notification);
            state.notifications.push(notification);
          });
      }
    },
    LOGOUT(state) {
      state.isAuthenticated = false;
      state.token = '';
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_type');
      state.userId = null;
      state.username = null;

      state.showSidenav = false;
      state.showConfig = false;
      state.showNavbar = false;
      state.showFooter = false;
    },
    HIDE_ALL(state) {
      state.showNavs = false;
      state.showSidenav = false;
      state.showNavbar = false;
      state.showFooter = false;
      state.showMain = false;
    },
    SHOW_ALL(state) {
      state.showNavs = true;
      state.showSidenav = true;
      state.showNavbar = true;
      state.showFooter = true;
      state.showMain = true;
    },

    toggleDarkMode(state) {
      state.isDarkMode = !state.isDarkMode;
      // Persist the dark mode state in local storage
      localStorage.setItem('isDarkMode', state.isDarkMode);
    },
    initializeDarkMode(state) {
      const savedDarkMode = localStorage.getItem('isDarkMode');
      if (savedDarkMode !== null) {
        state.isDarkMode = savedDarkMode === 'true';
      }
    }
  },
  actions: {
    toggleDarkMode({ commit }) {
      commit('toggleDarkMode');
    },
    initializeDarkMode({ commit }) {
      commit('initializeDarkMode');
    },
    setColor({ commit }, payload) {
      commit("color", payload);
    },
    async login({ commit }, credentials) {
      try {
        const response = await login(credentials); // API call to Laravel login endpoint
        const { token, user } = response;
        commit('SET_AUTH', { token, userType: user.user_type, userId: user.id, username:user.username });

      } catch (error) {
        console.error('Login failed:', error);
      }
    },
    async register(_, userData) { // Removed 'commit' as it's not used
      await register(userData);
    },
    async logout({ commit }) {
      await logout();
      commit('LOGOUT');
    },
    initializeAuth({ commit }) {
      const token = localStorage.getItem('access_token');
      const userType = localStorage.getItem('user_type');
      const userId = localStorage.getItem('user_id');
      const username = localStorage.getItem('username');

      if (token && userType && userId, username) {
        commit('SET_AUTH', { token, userType, userId,username });
      }
    },
  
    hideAllElements({ commit }) {
      commit('HIDE_ALL');
    },
    showAllElements({ commit }) {
      commit('SHOW_ALL');
    },
    initializeLanguage({ commit }) {
      const savedLanguage = localStorage.getItem("language") || "en";
      commit("setLanguage", savedLanguage);
    },
    changeLanguage({ commit }, language) {
      commit("setLanguage", language);
    },
  },
  getters: {
    userType: state => state.userType,
    isDarkMode: state => state.isDarkMode,
    language: (state) => state.language,
  },
});
