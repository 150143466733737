<template>
  <div :class="{'dark-mode': isDarkMode}" class="card z-index-2">
    <div
      class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent"
    >
      <div
        class="border-radius-lg py-3 pe-1"
        :class="`bg-gradient-${color} shadow-${color}`"
      >
        <slot />
      </div>
    </div>
    <div :class="{'dark-mode': isDarkMode}" class="card-body">
      <h6 :class="{'dark-mode': isDarkMode}"  class="mb-0">{{ title }}</h6>
      <!--  eslint-disable-next-line vue/no-v-html -->
      <p :class="{'dark-mode': isDarkMode}" class="text-sm" v-html="subtitle"></p>
      <hr class="dark horizontal" />

    </div>
  </div>
</template>

<script>
import { mapMutations, mapState,mapGetters  } from "vuex";
export default {
  name: "ChartHolderCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    update: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  computed:{
    isDarkMode() {
      // Access the `isDarkMode` state from Vuex or another state management system
      return this.$store.state.isDarkMode;
    },
    ...mapGetters(['isDarkMode']),
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'DINNextLTArabic-Bold';
  src: url('../../../public/fonts/DINNextLTArabic-Bold-4.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.dark-mode{
  color: white !important;
}

p{
  font-family: DINNextLTArabic-Bold;
  font-weight: 700;
}
h6{
  font-family: DINNextLTArabic-Bold;
  font-weight: 700;
  text-align: center;
}
</style>