<template>
    <div class="manage-salaries-container">
      <el-card class="box-card">
        <div class="filter-controls">
          <el-input
            v-model="searchQuery"
            placeholder="Search by employee name"
            clearable
            @clear="onFilterChange"
            @input="onFilterChange"
            class="search-input"
          ></el-input>
  
          <el-select v-model="selectedMonth" placeholder="Select Month" @change="onFilterChange">
            <el-option v-for="month in months" :key="month.value" :label="month.label" :value="month.value"></el-option>
          </el-select>
  
          <el-select v-model="selectedYear" placeholder="Select Year" @change="onFilterChange">
            <el-option v-for="year in years" :key="year" :label="year" :value="year"></el-option>
          </el-select>
  
          <el-select v-model="selectedCurrency" placeholder="Select Currency" @change="onFilterChange">
            <el-option label="LYD" value="LYD"></el-option>
            <el-option label="USD" value="USD"></el-option>
          </el-select>
        </div>
  
        <el-table :data="salaries" v-loading="loading" stripe>
          <el-table-column :label="$t('operations')" width="200">
            <template #default="scope">
              <el-button type="primary" @click="printSalary(scope.row)">
                {{ $t('print') }}
              </el-button>
              <el-button type="danger" @click="confirmDelete(scope.row.id)">
                {{ $t('delete') }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="employee.name" :label="$t('employeeName')" width="200"></el-table-column>
          <el-table-column prop="employee.base_salary" :label="$t('baseSalary')" width="120"></el-table-column>
          <el-table-column prop="bonus" :label="$t('bonus')" width="120"></el-table-column>
          <el-table-column prop="currency" :label="$t('currency')" width="100"></el-table-column>
          <el-table-column prop="net_salary" :label="$t('netSalary')" width="150"></el-table-column>
          <el-table-column prop="month" :label="$t('month')" width="100"></el-table-column>
          <el-table-column prop="year" :label="$t('year')" width="100"></el-table-column>
        </el-table>
  
        <!-- Pagination -->
        <el-pagination
          v-model:currentPage="pagination.current_page"
          :page-size="pagination.per_page"
          layout="prev, pager, next"
          :total="pagination.total"
          @current-change="handlePageChange"
        ></el-pagination>
      </el-card>
    </div>
  </template>
  
  <script>
  import '@/assets/css/main-style.css';
  import axios from 'axios';
  import logo from "@/assets/img/logo-school-one.png";
  import QRCode from 'qrcode.vue';
  import TajawalBold from "@/assets/fonts/Tajawal-Bold.ttf";
  import signatureHareth from "@/assets/img/signature_hareth.png";
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      console.log("Request config:", config);
      return config;
    }, 
    (error) => {
      console.error("Interceptor error:", error);
      return Promise.reject(error);
    }
  );
  
  export default {
    data() {
      return {
        searchQuery: '',
        selectedMonth: null,
        selectedYear: null,
        selectedCurrency: null,
        salaries: [],
        loading: false,
        months: [
            {value: '', label: 'All Months'},
          { value: 1, label: 'January' },
          { value: 2, label: 'February' },
          { value: 3, label: 'March' },
          { value: 4, label: 'April' },
          { value: 5, label: 'May' },
          { value: 6, label: 'June' },
          { value: 7, label: 'July' },
          { value: 8, label: 'August' },
          { value: 9, label: 'September' },
          { value: 10, label: 'October' },
          { value: 11, label: 'November' },
          { value: 12, label: 'December' },
        ],
        years: Array.from({ length: 30 }, (v, k) => new Date().getFullYear() - k),
        pagination: {
          total: 0,
          current_page: 1,
          per_page: 10,
        },
      };
    },
    methods: {
      async confirmDelete(id) {
    this.$confirm('Are you sure you want to delete this salary record?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warning',
    }).then(async () => {
      try {
        await api.delete(`/salaries/${id}`); // Make the API call to soft delete the salary
        this.$message.success('Salary deleted successfully.');
        this.fetchSalaries(this.pagination.current_page); // Refresh the salary list
      } catch (error) {
        this.$message.error('Failed to delete salary.');
      }
    }).catch(() => {
      this.$message.info('Delete action canceled.');
    });
  },
      printSalary(salary) {
  // Check if there are any deductions to display
  let deductionsContent = '';
  let totalDeductions = 0;

  if (salary.deductionsShow && salary.deductionsShow.length > 0) {
    salary.deductionsShow.forEach(deduction => {
      deductionsContent += `
        <tr>
          <td>${deduction.reason}</td>
          <td>${deduction.amount}</td>
        </tr>
      `;
      totalDeductions += parseFloat(deduction.amount); // Sum the deductions
    });
  }

  const printContent = `
    <main class="main-wrapper position-relative">
      <div class="modern-invoice3" id="download-section">
        <!-- Invoice Top Section -->
        <div class="invoice-top">
          <div class="row align-items-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center text-sm-start mb-3 mb-sm-1">
              <img style="width:50%;height: 50%;" src="${require('@/assets/img/logo-school.png')}" alt="invoice">
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center text-sm-end mb-3 mb-sm-1">
              <h6 class="text-25 text-uppercase mb-0 mt-0">Receipt Voucher</h6>
            </div>
          </div>
          <hr>
        </div>
        <!-- Invoice Details Section -->
        <div class="invoice-details pt-20">
          <div class="row">
            <div class="col-sm-12 order-sm-0">
              <strong class="text-18 mb-3 d-inline-block">Date:</strong>
              <address class="mb-4">${new Date().toLocaleDateString()}</address>
            </div>
            <div class="col-sm-6 text-left">
              <strong  class="text-18 mb-3 d-inline-block">To Mr./Ms. ${salary.employee.name},</strong>
            </div>
          </div>
          <div class="col-sm-12 text-center">
            <p class="mb-4">
              نود أن نشكرك على جهودك والتزامك الرائع. نحن فخورون بكونك جزءاً من عائلة مدرسة الماســة. نحن نؤمن بأنك عنصر أساسي في نجاحنا، ونقدر إبداعك وروحك الإيجابية التي تجلبها يومياً. استمر في العطاء والإبداع، فنحن دائماً ندعمك!
            </p>
            <p class="mb-4">
              We would like to thank you for your efforts and outstanding commitment. We are proud to have you as part of the Almasa School family. We believe you are a key element in our success, and we appreciate your creativity and positive spirit that you bring every day. Keep up the great work, we always have your back!
            </p>
          </div>
        </div>
        <!-- Salary Table Section -->
        <div class="table-responsive invoice-table mb-4">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Employee Type</th>
                <th>Base Salary</th>
                <th>Currency</th>
                <th>Month</th>
                <th>Year</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${salary.employee.name}</td>
                <td>${salary.employee.type || 'N/A'}</td>
                <td>${salary.employee.base_salary}</td>
                <td>${salary.currency}</td>
                <td>${salary.month}</td>
                <td>${salary.year}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Deductions Table Section (only if deductions are present) -->
        ${salary.deductionsShow && salary.deductionsShow.length > 0 ? `
        <div class="table-responsive invoice-table mb-4">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Deduction Reason</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              ${deductionsContent}
            </tbody>
          </table>
        </div>
        ` : ''}
        <!-- Summary Section -->
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-6"></div>
          <div class="col-lg-4 col-md-4 col-sm-6 ms-auto">
            <table class="table">
              <tbody>
                <tr>
                  <td><strong>Bonus</strong></td>
                  <td>${salary.bonus}</td>
                </tr>
                ${totalDeductions > 0 ? `
                <tr>
                  <td><strong>Total Deductions</strong></td>
                  <td>${totalDeductions}</td>
                </tr>` : ''}
                <tr class="total-pay">
                  <td><strong>Net Salary</strong></td>
                  <td><strong>${salary.net_salary}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- QR Code and Signatures Section -->
        <div class="row">
          <div class="col-sm-12 text-center mb-4">
            <qrcode-vue :value="${salary.id}" :size="128" :level="'H'"></qrcode-vue> <!-- QR Code for salary ID -->
          </div>
          <div class="col-sm-4 text-center">
              <img src="${signatureHareth}" alt="Signature" style="width: 200px; height: auto;" />
            <hr><p>Director of Financial Affairs</p><hr>
          </div>
          <div class="col-sm-4 text-center">
              <img src="${signatureHareth}" alt="Signature" style="width: 200px; height: auto;" />
            <hr><p>Director of Human Resources</p><hr>
          </div>
          <div class="col-sm-4 text-center">
              <img src="${signatureHareth}" alt="Signature" style="width: 200px; height: auto;" />
            <hr><p>Chairman of the Board</p><hr>
          </div>
        </div>
      </div>
    </main>
  `;

  // Open a new window and write the receipt HTML into it
  const newWindow = window.open('', '', 'width=800, height=600');
  newWindow.document.write(`
    <html>
      <head>
        <title>Salary Receipt</title>
        <link rel="stylesheet" href="/main-style.css">
      </head>
      <body>${printContent}</body>
      <style>
           @font-face {
          font-family: 'TajawalBold';
          src: url('${TajawalBold}') format('truetype');
        }
          p{
          font-family: 'TajawalBold';
          }
          strong{
          font-family: 'TajawalBold';
          }
      </style>
    </html>
  `);

  newWindow.document.close();

  // Print after a brief delay
  setTimeout(() => {
    newWindow.print();
    newWindow.close();
  }, 500);
},



      async fetchSalaries(page = 1) {
        this.loading = true;
  
        // Include page number explicitly
        const params = {
          page: page,
          ...(this.searchQuery && { search: this.searchQuery }),
          ...(this.selectedMonth && { month: this.selectedMonth }),
          ...(this.selectedYear && { year: this.selectedYear }),
          ...(this.selectedCurrency && { currency: this.selectedCurrency }),
        };
  
        try {
          const response = await api.get('/salaries', { params });
  
          this.salaries = response.data.data;
  
          if (response.data.meta && response.data.meta.pagination) {
            this.pagination = response.data.meta.pagination;
          } else {
            console.warn('No pagination data found in response');
          }
        } catch (error) {
          this.$message.error('Failed to load salaries.');
        } finally {
          this.loading = false;
        }
      },
      onFilterChange() {
        // Reset to page 1 when filters change and fetch
        this.pagination.current_page = 1;
        this.fetchSalaries(1);
      },
      handlePageChange(page) {
        // Only trigger page change without resetting filters
        this.fetchSalaries(page);
      },
    },
    mounted() {
      this.fetchSalaries();
    },
  };
  </script>
  
  
  <style scoped>
  .manage-salaries-container {
    padding: 20px;
  }
  .filter-controls {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  .search-input {
    width: 250px;
  }


  /* */
  </style>
  