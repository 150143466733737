<template>
  <div class=" mx-auto   p-xl-4 p-md-3 p-sm-1">
    <el-card class="mb-4">

      <div class="mb-4 col-g-12 col-12 d-flex flex-row ">
        <div class="col-6 p-0 d-flex">
          <input v-model="parent_id" placeholder="" class=" form-control col-lg-6 col-md-6 col-sm-10" />
          <el-button type="primary" class="col-lg-1 col-md-2 col-sm-2" @Click="searchParent">
            <i class="material-icons-round opacity-10 fs-5">person_search</i>
          </el-button>
          <div v-if="this.errors">
            <span class="text-danger" v-if="this.errors.parent_id">{{ this.errors.parent_id[0] }}</span>
          </div>
        </div>

      </div>
      <div class="col-12 " v-if="parentInfo.message == 'success'">

        <p>Parent name</p>
        <p>{{ parentInfo.data[0].first_name + ' ' + parentInfo.data[0].last_name }} </p>


      </div>
      <div class="col-12" v-if="parentInfo.message == 'success'">
        <div class="col-6 p-0 d-flex">
          <input v-model="pin_code" placeholder="" class=" form-control col-lg-6 col-md-6 col-sm-10" />
          <el-button type="primary" class="col-lg-1 col-md-2 col-sm-2" @Click="submitPinCode">
            <i class="material-icons-round opacity-10 fs-5">person_search</i>
          </el-button>
          <div v-if="this.errors">
            <span class="text-danger" v-if="this.errors.pin_code">{{ this.errors.pin_code[0] }}</span>
          </div>
        </div>
      </div>





      <div class="col-12 " v-if="FamilyInfo.message == 'success'">

        <h1>Chides :</h1>
       

        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 m-2 my-4" style="direction: ltr;" v-for=" item in FamilyInfo.data[0].students  ">
          <div class="card mb-2 h-100">
            <div class="card-header p-3 pt-2 h-100">
              <div
                class="icon icon-lg icon-shape shadow text-center border-radius-xl mt-n5 position-absolute bg-gradient-dark shadow-dark">
                
                <img src="https://demos.creative-tim.com/vue-material-dashboard-2/img/team-4.16a9baef.jpg" alt="kal" class="shadow null border-radius-lg w-100">
                <!-- <i class="material-icons opacity-10 text-white" aria-hidden="true">weekend</i> -->
              </div>
              <div class="pt-1 text-end">
                <p class="text-sm mb-0 text-capitalize"> Student name </p>
                <h4 class="mb-0">{{ item.name }}</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0">
            <div class="card-footer p-3 row text-end">
              <p class="mb-0 col-6">
                Class
                <span class="text-success text-sm font-weight-bolder" v-if="item.class">
                  {{ item.class.name }}

                </span>      
                <span class="text-success text-sm font-weight-bolder" v-else>
                  -

                </span> 
              </p>
              <p class="mb-0 col-6">
                Section
                <span class="text-success text-sm font-weight-bolder" v-if="item.section">
                  {{ item.section.name }}

                </span>      
                <span class="text-success text-sm font-weight-bolder" v-else>
                  -

                </span> 
              </p>
            </div>
          </div>
        </div>

        </div>




      </div>


    </el-card>

  </div>
</template>

<script>
import axios from 'axios';
import { ElCard, ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElButton } from 'element-plus';
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
});

// Interceptors for auth token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default {
  components: {
    ElCard,
    ElSelect,
    ElOption,
    ElInput,
    ElTable,
    ElTableColumn,

    ElButton,

  },
  data() {
    return {
      ParentAndStudents: [],
      parent_id: "",
      parentInfo: [],
      FamilyInfo: [],
      pin_code: "",
      errors: Object,


    };
  },

  computed: {

  },

  methods: {
    getParentIdFromUrll() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const parent_id = urlParams.get('parent_id')
      this.parent_id = parent_id;
      if(this.parent_id){
        this.searchParent()
      }
    },


    async submitPinCode() {
      this.FamilyInfo = [];
      this.errors = []

      try {
        const response = await api.post('/parent-chaildrens-data', {
          parent_id: this.parent_id,
          pin_code: this.pin_code
        });
        this.FamilyInfo = response.data;
      } catch (error) {
        this.errors = error.response.data.errors;


      }
    },


    async searchParent() {
      this.pin_code = "";
      this.parentInfo = [];
      this.errors = []
      try {
        const response = await api.post('/verify-parent-identity-page', {
          parent_id: this.parent_id
        });
        this.parentInfo = response.data;
      } catch (error) {
        this.errors = error.response.data.errors;

        // console.error('Error submitting bill:', error);
      }
    },
  },
  mounted() {


    this.getParentIdFromUrll();
  },


};
</script>

<style scoped>
.container {
  max-width: 1200px;
}

.table-wrapper {
  max-height: 300px;
  overflow-x: auto;
  overflow-y: auto;
  /* Allows vertical scroll if needed */
}




/* Padding Utility Classes */
.p-0 {
  padding: 0;
  /* No padding */
}

.p-1 {
  padding: 0.25rem !important;
  /* Extra Small padding */
}

.p-2 {
  padding: 0.5rem !important;
  /* Small padding */
}

.p-3 {
  padding: 1rem !important;
  /* Medium padding */
}

.p-4 {
  padding: 1.5rem !important;
  /* Large padding */
}

.p-5 {
  padding: 2rem !important;
  /* Extra Large padding */
}

/* Responsive Padding Classes */
@media (min-width: 576px) {

  /* Small devices (≥576px) */
  .p-sm-1 {
    padding: 0.5rem !important;
    /* Small padding */
  }

  .p-sm-2 {
    padding: 1rem !important;
    /* Medium padding */
  }

  .p-sm-3 {
    padding: 1.5rem !important;
    /* Large padding */
  }

  .p-sm-4 {
    padding: 2rem !important;
    /* Extra Large padding */
  }
}

@media (min-width: 768px) {

  /* Medium devices (≥768px) */
  .p-md-1 {
    padding: 0.5rem !important;
    /* Small padding */
  }

  .p-md-2 {
    padding: 1rem !important;
    /* Medium padding */
  }

  .p-md-3 {
    padding: 1.5rem !important;
    /* Large padding */
  }

  .p-md-4 {
    padding: 2rem !important;
    /* Extra Large padding */
  }
}

@media (min-width: 992px) {

  /* Large devices (≥992px) */
  .p-lg-1 {
    padding: 0.5rem !important;
    /* Small padding */
  }

  .p-lg-2 {
    padding: 1rem !important;
    /* Medium padding */
  }

  .p-lg-3 {
    padding: 1.5rem !important;
    /* Large padding */
  }

  .p-lg-4 {
    padding: 2rem !important;
    /* Extra Large padding */
  }
}

@media (min-width: 1200px) {

  /* Extra Large devices (≥1200px) */
  .p-xl-1 {
    padding: 0.5rem !important;
    /* Small padding */
  }

  .p-xl-2 {
    padding: 1rem !important;
    /* Medium padding */
  }

  .p-xl-3 {
    padding: 1.5rem !important;
    /* Large padding */
  }

  .p-xl-4 {
    padding: 2rem !important;
    /* Extra Large padding */
  }
}

/* Larger than Extra Large devices (≥1400px) */
@media (min-width: 1400px) {
  .p-5 {
    padding: 2.5rem !important;
    /* Extra Extra Large padding */
  }
}

.form-control {

  height: calc(2.25rem + 2px);
  /* Adjusted height for consistency */
  padding: 0.375rem 0.75rem;
  /* Vertical and horizontal padding */
  font-size: 1rem;
  /* Font size */
  font-weight: 400;
  /* Font weight */
  line-height: 1.5;
  /* Line height */
  color: #495057;
  /* Text color */
  background-color: #fff;
  /* Background color */
  background-clip: padding-box;
  /* Background clipping */
  border: 1px solid #ced4da;
  /* Border color */
  border-radius: 0.25rem;
  /* Rounded corners */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* Transition effects */
}

/* Focus state */
.form-control:focus {
  color: #495057;
  /* Text color on focus */
  background-color: #fff;
  /* Background color on focus */
  border-color: #80bdff;
  /* Border color on focus */
  outline: 0;
  /* Remove outline */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  /* Box shadow for focus */
}

/* Disabled state */
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  /* Background for disabled */
  opacity: 1;
  /* Full opacity */
}
</style>
