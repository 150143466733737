<template>
    <div>
      <!-- Employee List -->
        <!-- Filter and Search Options -->
    <el-row :gutter="20" style="margin-bottom: 20px;">
      <el-col :span="6">
        <el-input v-model="searchQuery" placeholder="Search by Name" @input="handleSearch"></el-input>
      </el-col>
      <el-col :span="6">
        <el-select v-model="selectedDepartment" placeholder="Filter by Department" @change="handleSearch">
          <el-option
            v-for="department in departments"
            :key="department.id"
            :label="department.name"
            :value="department.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-select v-model="selectedEmployeeType" placeholder="Filter by Employee Type" @change="handleSearch">
          <el-option
            v-for="type in employeeTypes"
            :key="type.id"
            :label="type.name"
            :value="type.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-select v-model="selectedTeacherType" placeholder="Filter by Teacher Type" @change="handleSearch">
          <el-option
            v-for="type in teacherTypes"
            :key="type.id"
            :label="type.type"
            :value="type.id"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
  <!-- Employee List -->
  <el-table :data="employees" style="max-width: 100%; max-height: 400px; overflow: auto;" border stripe>
    <!-- Employee Name Column -->
    <el-table-column :label="$t('employeeName')" prop="name">
      <template #default="scope">
        {{ scope.row.name }}
      </template>
    </el-table-column>
  
    <!-- Position Column -->
    <el-table-column :label="$t('employeeType')">
      <template #default="scope">
        {{ scope.row.employee_type ? scope.row.employee_type.name : 'No Type Assigned' }}
      </template>
    </el-table-column>
  
    <!-- Department Column -->
    <el-table-column :label="$t('department')">
      <template #default="scope">
        {{ scope.row.department ? scope.row.department.name : 'Not specified' }}
      </template>
    </el-table-column>
  
    <!-- Teacher Types Column -->
    <el-table-column :label="$t('teacherType')">
      <template #default="scope">
        <div v-if="scope.row.teacher_types && scope.row.teacher_types.length > 0">
          <ul>
            <li v-for="(type, index) in scope.row.teacher_types" :key="index">
              {{ type.type }}
            </li>
          </ul>
        </div>
        <span v-else>Not specified</span>
      </template>
    </el-table-column>
  
    <!-- Classes Column -->
    <el-table-column :label="$t('class')">
      <template #default="scope">
        <div v-if="scope.row.classes && scope.row.classes.length > 0">
          <ul>
            <li v-for="(classItem, index) in scope.row.classes" :key="index">
              {{ classItem.name }}
            </li>
          </ul>
        </div>
        <span v-else>Not specified</span>
      </template>
    </el-table-column>
  
    <!-- Sections Column -->
    <el-table-column :label="$t('section')">
      <template #default="scope">
        <div v-if="scope.row.sections && scope.row.sections.length > 0">
          <ul>
            <li v-for="(section, index) in scope.row.sections" :key="index">
              {{ section.name }}
            </li>
          </ul>
        </div>
        <span v-else>Not specified</span>
      </template>
    </el-table-column>
  
    <!-- Actions Column -->
    <el-table-column :label="$t('actions')" align="center" width="150">
      <template #default="scope">
        <el-button type="primary" @click="openSalaryDialog(scope.row)" size="mini">{{$t('setSalary')}}</el-button>
      </template>
    </el-table-column>
  </el-table>

   <!-- Pagination Component -->
   <el-pagination
   background
   layout="prev, pager, next"
   :total="totalEmployees"
   :page-size="pageSize"
   @current-change="handlePageChange"
   :current-page="currentPage"
 ></el-pagination>
  
  
      <!-- Salary Dialog -->
<!-- Salary Dialog -->
<el-dialog v-model="salaryDialogVisible" title="Set Employee Salary" width="650px">
  <el-form :model="selectedSalary" ref="salaryForm" label-width="130px" class="salary-form">
    
    <!-- Basic Salary Details Section -->
    <div class="form-section">
      <h4>{{$t('salaryDetails')}}</h4>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="$t('baseSalary')">
            <el-input v-model="selectedSalary.base_salary" type="number" placeholder="Base salary" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('bonus')">
            <el-input v-model="selectedSalary.bonus" type="number" placeholder="Enter bonus amount"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="$t('allowance')">
            <el-input v-model="selectedSalary.allowance" type="number" placeholder="Enter allowance"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('currency')">
            <el-select v-model="selectedSalary.currency" placeholder="Select currency">
              <el-option label="LYD" value="LYD"></el-option>
              <el-option label="USD" value="USD"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </div>

    <!-- Time Period Section -->
    <div class="form-section">
      <h4>{{$t('period')}}</h4>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="$t('month')">
            <el-select v-model="selectedSalary.month" placeholder="Select month">
              <el-option v-for="month in months" :key="month.value" :label="month.label" :value="month.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('year')">
            <el-input v-model="selectedSalary.year" type="number" placeholder="Enter year"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>

    <!-- Deductions Section -->
    <div class="form-section">
      <h4>{{$t('deductions')}}</h4>
      <el-form-item>
        <el-table :data="deductions" style="width: 100%" @selection-change="handleDeductionSelection" v-if="deductions.length > 0">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column property="reason" label="Description"></el-table-column>
          <el-table-column property="amount" label="Amount"></el-table-column>
        </el-table>
        <p v-else>No deductions available.</p>
      </el-form-item>
    </div>

    <!-- Absences Section -->
    <div class="form-section">
      <h4>{{$t('absences')}}</h4>
      <el-form-item>
        <el-table :data="absences" style="width: 100%" @selection-change="handleAbsenceSelection" v-if="absences.length > 0">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column property="date" label="Date"></el-table-column>
          <el-table-column property="absence_type.name" label="Absence Type"></el-table-column>
        </el-table>
        <p v-else>No absences for this employee.</p>
      </el-form-item>
    </div>

    <!-- Form Submit & Cancel Buttons -->
    <el-form-item class="dialog-footer">
      <el-button type="primary" @click="submitSalaryForm">Submit</el-button>
      <el-button @click="salaryDialogVisible = false">Cancel</el-button>
    </el-form-item>

  </el-form>
</el-dialog>

    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  import { debounce } from 'lodash';
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default {
  data() {
    return {
      employees: [],
      salaryDialogVisible: false,
      totalEmployees: 0,
      pageSize: 10,
      currentPage: 1,
      searchQuery: '',
      selectedDepartment: null,
      selectedEmployeeType: null,
      selectedTeacherType: null,
      departments: [],
      employeeTypes: [],
      teacherTypes: [],
      selectedSalary: {
        base_salary: null,
        bonus: null,
        allowance: null,
        currency: "LYD", // Default value
        month: null,
        year: null,
        employee_id: null,
        selectedDeductions: [], // To hold selected deductions
        selectedAbsences: [] // To hold selected absences
      },
      months: [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
      ],
      deductions: [],
      absences: []
    };
  },
  watch: {
    'selectedSalary.month': 'fetchDeductionsAndAbsences',
    'selectedSalary.year': 'fetchDeductionsAndAbsences',
  },
  methods: {
    async fetchEmployees() {
  try {
    console.log('Fetching employees with params:', {
      page: this.currentPage,
      per_page: this.pageSize,
      name: this.searchQuery,
      department_id: this.selectedDepartment,
      employee_type_id: this.selectedEmployeeType,
      teacher_type_id: this.selectedTeacherType,
    });

    const response = await api.get('/employees-issued-salaries', {
      params: {
        page: this.currentPage,
        per_page: this.pageSize,
        name: this.searchQuery,
        department_id: this.selectedDepartment,
        employee_type_id: this.selectedEmployeeType,
        teacher_type_id: this.selectedTeacherType,
      },
    });
    this.employees = response.data.data;
    this.totalEmployees = response.data.total;
  } catch (error) {
    console.error('Error fetching employees:', error);
    this.$message.error('Failed to load employees.');
  }
},

    async fetchFilters() {
      try {
        const response = await api.get('/departments-teacher-types');
        this.departments = response.data.departments;
        this.teacherTypes = response.data.teacherTypes;
        // assuming employeeTypes are coming from the same endpoint
        this.employeeTypes = response.data.employeeTypes;
      } catch (error) {
        console.error('Error fetching filter options:', error);
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchEmployees();
    },
    handleSearch: debounce(function () {
      this.currentPage = 1;
      this.fetchEmployees();
    }, 300),
    openSalaryDialog(employee) {
      this.salaryDialogVisible = true;
      this.selectedSalary.employee_id = employee.id;
      this.selectedSalary.base_salary = employee.base_salary; // Optional for display only
    },
    async fetchDeductionsAndAbsences() {
      if (this.selectedSalary.month && this.selectedSalary.year) {
        await this.fetchEmployeeDeductions(this.selectedSalary.employee_id, this.selectedSalary.month, this.selectedSalary.year);
        await this.fetchEmployeeAbsences(this.selectedSalary.employee_id, this.selectedSalary.month, this.selectedSalary.year);
      }
    },
    async fetchEmployeeDeductions(employeeId, month, year) {
      try {
        const response = await api.get('/deductions', {
          params: { employee_id: employeeId, month, year },
        });
        this.deductions = response.data || []; // Assuming deductions array
      } catch (error) {
        this.$message.error('Failed to load deductions.');
      }
    },
    async fetchEmployeeAbsences(employeeId, month, year) {
      try {
        const response = await api.get('/absences', {
          params: { employee_id: employeeId, month, year },
        });
        this.absences = response.data || []; // Assuming absences array
      } catch (error) {
        this.$message.error('Failed to load absences.');
      }
    },
    handleDeductionSelection(selectedDeductions) {
  this.selectedSalary.selectedDeductions = selectedDeductions;
},
    handleAbsenceSelection(selectedAbsences) {
      this.selectedSalary.selectedAbsences = selectedAbsences;
    },
    async submitSalaryForm() {
  try {
    // Step 1: Submit the salary and receive the salary ID
    const salaryResponse = await api.post('/salaries', {
      ...this.selectedSalary,
      deductions: this.selectedSalary.selectedDeductions.map(d => d.id), // Submit selected deductions
    });

    const salaryId = salaryResponse.data.salary_id; // Get the salary ID from the response

    // Step 2: Save the relations in the pivot table (if needed)
    await api.post('/salary-deductions-absences', {
      salary_id: salaryId,
      deduction_ids: this.selectedSalary.selectedDeductions.map(d => d.id),
      absence_ids: this.selectedSalary.selectedAbsences.map(a => a.id), // Submit selected absences
      employee_id: this.selectedSalary.employee_id
    });

    this.$message.success('Salary updated and relationships stored successfully');
    this.salaryDialogVisible = false;
  } catch (error) {
    console.error('Error submitting salary:', error);
    this.$message.error('Failed to update salary.');
  }
}


  },
  mounted() {
    this.fetchEmployees();
    this.fetchFilters();
  }
};

  </script>
  <style scoped>
  .salary-form .form-section {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
  }
  .salary-form h4 {
    margin-bottom: 10px;
    color: #409eff;
  }
  .dialog-footer {
    text-align: right;
  }
  </style>