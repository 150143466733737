<template>
  <el-card class="custom-card">
    <!-- Title -->
    <h3 class="title">Issuance of Financial Documents</h3> 

    <!-- Description -->
    <p class="description">
      اختر الطالب او الطلبة الاخوة الذي سيتم اصدار لهم واصل مالي
      <strong>أمام إسم كل طالب سيكون إسم ولي أمره</strong>
    </p>

    <!-- Form -->
    <el-form :model="form" @submit.prevent="submitForm" class="form-container">
      
      <!-- Select Students (Multiple Selection) -->
      <el-form-item label="Select Students" class="form-item">
        <el-select
          v-model="form.student_ids"
          multiple
          placeholder="Select students"
          @change="fetchParentAndFees"
          filterable
          :filter-method="filterByParentOrStudent"
        >
          <el-option
            v-for="student in filteredStudents"
            :key="student.id"
            :label="`${student.name} (إسم ولي الأمر: ${student.parentName})`"
            :value="student.id"
          />
        </el-select>
      </el-form-item>

      <!-- Parent Name -->
      <el-form-item label="Parent Name" v-if="parent" class="form-item">
        <el-input v-model="formattedParentName" disabled></el-input>
      </el-form-item>

      <!-- Fee Amounts for Each Student -->
      <div v-for="studentId in form.student_ids" :key="studentId" class="student-fees">
        <h5>رسوم الإشتراك للطالب/ة {{ getStudentNameById(studentId) }}</h5>

        <!-- Select Fees for Each Student -->
        <el-form-item label="اختر رسوم الإشتراك" required class="form-item">
          <el-select
            v-model="studentFees[studentId]"
            multiple
            placeholder="Select fees for this student"
            @change="updateStudentFees(studentId)"
          >
            <el-option
              v-for="fee in subscriptionFees"
              :key="fee.id"
              :label="`${fee.category} - ${fee.sub_category} - ${fee.amount}$`"
              :value="fee.id"
            />
          </el-select>
        </el-form-item>

        <!-- Display Selected Fees and Amounts for the Student -->
        <div v-for="feeId in studentFees[studentId]" :key="feeId">
          <el-form-item :label="`Amount for ${getFeeLabel(feeId)}`" class="form-item">
            <el-input :value="getFeeAmount(feeId)" type="number" disabled></el-input>
          </el-form-item>
        </div>
      </div>

      <!-- Select Treasury -->
      <el-form-item label="Select Treasury" required class="form-item">
        <el-select v-model="form.treasury_id" placeholder="Select a treasury" filterable>
          <el-option
            v-for="treasury in treasuries"
            :key="treasury.id"
            :label="treasury.name"
            :value="treasury.id"
          />
        </el-select>
      </el-form-item>

      <!-- Payment Method -->
      <el-form-item label="Payment Method" required class="form-item">
        <el-radio-group v-model="form.payment_method">
          <el-radio label="cash">Cash</el-radio>
          <el-radio label="instrument">Financial Instrument</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- Bank Information (Visible only if instrument is selected) -->
      <el-form-item v-if="form.payment_method === 'instrument'" label="Bank Name" class="form-item">
        <el-input v-model="form.bank_name" placeholder="Enter bank name" />
      </el-form-item>

      <el-form-item v-if="form.payment_method === 'instrument'" label="Branch Name" class="form-item">
        <el-input v-model="form.branch_name" placeholder="Enter branch name" />
      </el-form-item>

      <el-form-item v-if="form.payment_method === 'instrument'" label="Account Number" class="form-item">
        <el-input v-model="form.account_number" placeholder="Enter account number" />
      </el-form-item>

      <!-- Value Received -->
      <el-form-item label="Value Received" required class="form-item">
        <el-input v-model.number="form.value_received" type="number" placeholder="Enter value received" />
      </el-form-item>

      <!-- Total Amount -->
      <el-form-item label="Total Amount" class="form-item total-amount">
        <el-input :value="totalAmount" type="number" disabled></el-input>
      </el-form-item>

      <!-- Discount (Optional) -->
      <el-form-item label="Discount (Optional)" class="form-item">
        <el-input v-model.number="form.discount" type="number" placeholder="Enter discount" />
      </el-form-item>

      <!-- Submit Button -->
      <el-form-item class="form-item">
        <el-button type="primary" @click="submitForm">Issue Document</el-button>
      </el-form-item>
    </el-form>

    <!-- Show Receipt Number After Document is Issued -->
    <div v-if="receiptNumber" class="receipt-alert">
      <el-alert title="Financial Document Issued" type="success" />
      <div>Receipt Number: {{ receiptNumber }}</div>
    </div>

    <!-- Optional: Display Logo -->
    <img :src="logo" alt="School Logo" class="logo" />
  </el-card>
</template>

<script>
import axios from "axios";
import { ElMessage } from 'element-plus';
import logo from "@/assets/img/logo-school.png";
import Echo from '../../echo'
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token'); // Get token from local storage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


export default {
  data() {
    return {
      students: [],
      filteredStudents: [],
      subscriptionFees: [],
      notificationChannel: null,
      treasuries: [],
      parent: null,
      form: {
        student_ids: [],
        treasury_id: null,
        payment_method: '',
        value_received: '',
        bank_name: '',
        branch_name: '',
        account_number: '',
        discount: '',
      },
      studentFees: {}, // Stores selected fees for each student
      feeAmounts: {},  // Stores amounts for selected fees for each student
      receiptNumber: null,
      totalAmount: 0, // Stores the total amount for all selected students
    };
  },
  computed: {
    formattedParentName() {
      return this.parent ? `${this.parent.first_name} ${this.parent.last_name}` : '';
    }
  },
  methods: {
    loadNotifications() {
        const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
        // Set the notifications in your Vue component's state or display them
        this.notifications = notifications; // Assuming you have a data property called notifications
    },
    storeNotification(notification) {
        // Retrieve existing notifications from local storage
        const notifications = JSON.parse(localStorage.getItem('notifications')) || [];
        notifications.push(notification);

        // Save updated notifications back to local storage
        localStorage.setItem('notifications', JSON.stringify(notifications));
    },
    filterByParentOrStudent(query) {
    if (!query) {
      this.filteredStudents = this.students;
    } else {
      this.filteredStudents = this.students.filter(student => {
        const studentNameMatches = student.name.toLowerCase().includes(query.toLowerCase());
        const parentNameMatches = student.parentName.toLowerCase().includes(query.toLowerCase());
        return studentNameMatches || parentNameMatches;
      });
    }
  },
  async fetchParentAndFees(studentIds) {
  
    const firstStudentId = studentIds[0];
    try {
      const response = await api.get(`/subscription-fees/${firstStudentId}`);
      this.subscriptionFees = response.data.subscriptionFees;
      this.parent = response.data.parent; // Get parent data
      this.totalAmount = 0; // Reset total amount
      // Reset studentFees and feeAmounts when students are selected/changed
      studentIds.forEach(studentId => {
        this.$set(this.studentFees, studentId, []);
      });
      // Recalculate total amount after fetching fees
      this.calculateTotalAmount();
    } catch (error) {
      // Handle error
    }
  },
  getStudentNameById(studentId) {
    const student = this.students.find(s => s.id === studentId);
    return student ? student.name : 'Unknown';
  },
  getFeeLabel(feeId) {
    const fee = this.subscriptionFees.find(f => f.id === feeId);
    return fee ? `${fee.category} - ${fee.sub_category}` : 'Unknown Fee';
  },
  getFeeAmount(feeId) {
    const fee = this.subscriptionFees.find(f => f.id === feeId);
    return fee ? fee.amount : 0;
  },
  updateStudentFees(studentId) {
    if (!this.studentFees[studentId]) {
      this.$set(this.studentFees, studentId, []);
    }
    this.calculateTotalAmount();
  },
  buildStudentFeesData() {
        const studentFeesData = {};
        const firstStudentId = this.form.student_ids[0];

        // Calculate total fees for all students and include in studentFeesData
        let totalFees = 0;

        this.form.student_ids.forEach(studentId => {
            studentFeesData[studentId] = this.studentFees[studentId].map(feeId => {
                const feeAmount = this.getFeeAmount(feeId);
                totalFees += feeAmount;
                return {
                    subscription_fee_id: feeId,
                    amount: feeAmount,
                };
            });
        });

        this.totalAmount = totalFees; // Set the total amount
        return studentFeesData;
    },
    calculateTotalAmount() {
        let totalFees = 0;

        // Calculate total fees for all selected students
        this.form.student_ids.forEach(studentId => {
            const studentTotal = (this.studentFees[studentId] || []).reduce((sum, feeId) => {
                return sum + this.getFeeAmount(feeId);
            }, 0);

            totalFees += studentTotal;
        });

        this.totalAmount = totalFees; // Update total amount
    },
  fetchStudents() {
    api.get('/students-index').then(response => {
      this.students = response.data.map(student => {
        return {
          ...student,
          parentName: `${student.parent.first_name} ${student.parent.last_name}`
        };
      });
      this.filteredStudents = this.students;
    }).catch(error => {
      console.error('Error fetching students:', error);
    });
  },
  fetchTreasuries() {
    api.get('/treasuries').then(response => {
      this.treasuries = response.data.filter(treasury => treasury.type === 'manual');
    }).catch(error => {
      console.error('Error fetching treasuries:', error);
      ElMessage.error('Error fetching treasuries.');
    });
  },
    submitForm() {
      if (!this.form.student_ids.length || !this.form.treasury_id) {
        ElMessage.error('Please fill in all required fields.');
        return;
      }

      const studentFeesData = this.buildStudentFeesData();

      api.post('/financial-document', {
        ...this.form,
        student_fees: studentFeesData,
      }).then(response => {
        ElMessage.success(response.data.message);
        this.receiptNumber = response.data.receipt_number;
      }).catch(error => {
        console.error('Error issuing financial document:', error);
        ElMessage.error('Failed to issue the financial document.');
      });
    },


  },
  mounted() {
    this.fetchStudents();
    this.fetchTreasuries();


    const userId = localStorage.getItem('userId');
    const username = localStorage.getItem('username');
    console.log(userId); // Check if the user data is available

    if (userId) {
        // Store the channel reference for cleanup
        this.notificationChannel = Echo.private(`App.Models.User.${userId}`)
            .notification((notification) => {
                console.log('New Notification:', notification);

                // Get current date and time
                const currentDateTime = new Date();
                const date = currentDateTime.toLocaleDateString(); 
                const time = currentDateTime.toLocaleTimeString(); 

                let title = '';
                let type = ''; // Default to empty

                // Handle student added notification
                if (notification.message === 'A financial document has been issued.') {
                  title = `
                    تم إصدار مستند مالي جديد
                    المبلغ الكلي: ${notification.total_amount}
                    المبلغ النهائي: ${notification.final_amount}
                    التاريخ: ${date}
                    الوقت: ${time}
                    `;

                    type = 'success';
                }


                // Only trigger notification if title is set
                if (title) {
                    this.$notify({
                        title: title,
                        message: notification.message,
                        type: type // Use the determined type
                    });

                    // Store notification in local storage
                    this.storeNotification({ title: title });
                }
            });
    } else {
        console.error('User ID is null, cannot subscribe to notifications');
    }

    // Load previously stored notifications
    this.loadNotifications();
  }
};
</script>

<style scoped>
/* Card Styling */
.custom-card {
  background: linear-gradient(135deg, #f9f9f9, #e0e0e0);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}

/* Title Styling */
.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Description Styling */
.description {
  color: #666;
  font-size: 16px;
  margin-bottom: 20px;
}

/* Form Container */
.form-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Form Item Styling */
.form-item {
  margin-bottom: 20px;
}

/* Student Fees Section */
.student-fees {
  background-color: #f0f8ff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Total Amount */
.total-amount {
  font-weight: bold;
  color: #4caf50;
}

/* Receipt Alert */
.receipt-alert {
  margin-top: 20px;
}

/* Logo Styling */
.logo {
  margin-top: 20px;
  max-width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>