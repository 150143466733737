import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

// Retrieve the Pusher key and cluster from environment variables
const pusherKey = "528d8a427762f56dd985"
const pusherCluster = "eu"

window.Pusher = Pusher;

const echoInstance = new Echo({
    broadcaster: 'pusher',
    key: '528d8a427762f56dd985',
    cluster: 'eu',
    encrypted: true,
    forceTLS: true,
    authEndpoint: 'https://sys.almasa-sch.ly/api/broadcasting/auth',  // Ensure this is correct
    auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      withCredentials: true,  // Ensure credentials (cookies or tokens) are sent
    },
  });
  

export default echoInstance;
