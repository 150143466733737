<template>
  <div class="card mb-2" :class="directionReverse ? reverseDirection : ''">
    <div :class="{'dark-mode': isDarkMode}" class="card-header p-3 pt-2">
      <div
        class="icon icon-lg icon-shape shadow text-center border-radius-xl mt-n4 position-absolute"
        :class="`bg-gradient-${icon.background} shadow-${icon.background}`"
      >
        <i
          class="material-icons opacity-10"
          :class="icon.color"
          aria-hidden="true"
          >{{ icon.name }}</i
        >
      </div>
      <div class="pt-1" :class="isRTL ? 'text-start' : 'text-end'">
        <p :class="{'dark-mode': isDarkMode}" class="text-sm mb-0 text-capitalize">{{ title.text }}</p>
        <h4 :class="{'dark-mode': isDarkMode}" class="mb-0">{{ title.value }}</h4>
      </div>
    </div>
    <hr :class="{'dark-mode': isDarkMode}" class="horizontal my-0" />
    <div  class="card-footer p-3" :class="isRTL ? 'text-start' : 'text-end'
    ,
    { 'dark-mode': isDarkMode }
    ">
      <!--  eslint-disable-next-line vue/no-v-html -->
      <p class="mb-0" v-html="detail"></p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState,mapGetters  } from "vuex";
export default {
  name: "MiniStatisticsCard",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
  props: {
    title: {
      type: Object,
      required: true,
      text: String,
      value: [Number, String],
    },
    detail: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      required: true,
      name: String,
      color: String,
      background: String,
      default: () => ({
        color: "text-white",
        background: "success",
      }),
    },
    directionReverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["isRTL"]),
    isDarkMode() {
      // Access the `isDarkMode` state from Vuex or another state management system
      return this.$store.state.isDarkMode;
    },
    ...mapGetters(['isDarkMode']),
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'DINNextLTArabic-Bold';
  src: url('../../../public/fonts/DINNextLTArabic-Bold-4.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


p{
  font-family: DINNextLTArabic-Bold;
  font-weight: 700;
}
</style>