<template>
  <div>
    <!-- Search Input -->
    <el-input
      v-model="searchQuery"
      placeholder="Search by student name, parent, or receipt number"
      prefix-icon="el-icon-search"
      clearable
      @input="fetchFinancialDocuments"
    ></el-input>

    <!-- Financial Documents Table -->
    <el-table
      :data="financialDocuments"
      border
      style="width: 100%; margin-top: 20px"
    >
          <!-- Actions Column -->
          <el-table-column type="expand" label="Actions" width="350" :cell-class-name="operationCellClass">
            <template v-slot="scope">
              <el-button @click="printDocument(scope.row)">Print</el-button>
              <el-button @click="openUpdateDialog(scope.row)">Update</el-button>
              <el-button type="danger" @click="deleteDocument(scope.row.id)">Delete</el-button>
            </template>
          </el-table-column>
      <!-- Receipt Number Column -->
      <el-table-column prop="receipt_number" label="Receipt Number" width="180"></el-table-column>

      <!-- Student Name Column with background styling -->
      <!-- <el-table-column prop="student_name" label="Family's Parent In Almasa School" width="200">
        <template v-slot="scope">
          <div class="student-name-cell" :style="getStudentCellStyle(scope.row)">
            {{ scope.row.student_name }}
          </div>
        </template>
      </el-table-column> -->

      <!-- Parent Name Column -->
      <el-table-column prop="parent_name" label="Parent Name" width="200"></el-table-column>

      <!-- Total Amount Column -->
      <el-table-column prop="total_amount" label="Total Amount" width="150"></el-table-column>

      <!-- Discount Column -->
      <el-table-column prop="discount" label="Discount" width="150"></el-table-column>

      <!-- Final Amount Column -->
      <el-table-column prop="final_amount" label="Final Amount" width="150"></el-table-column>

      <!-- Value Received Column -->
      <el-table-column label="Value Received" width="150">
        <template v-slot="scope">
          <div :style="getValueReceivedCellStyle(scope.row)">
            {{ scope.row.value_received }}
          </div>
        </template>
      </el-table-column>

      <!-- Payment Method Column -->
      <el-table-column prop="payment_method" label="Payment Method" width="150"></el-table-column>

      <!-- Subscription Fees Column with background styling -->
      <el-table-column label="Subscription Fees Per Student" width="400">
        <template v-slot="scope">
          <div class="subscription-fees-cell">
            <div v-for="fee in scope.row.subscription_fees" :key="fee.subscription_fee_id" class="fee-details" :style="getFeeCellStyle(scope.row)">
              <strong>{{ fee.student_name }}</strong>: {{ fee.amount }} 
              <div>Category: {{ fee.category }}</div>
              <div>Sub-category: {{ fee.sub_category }}</div>
            </div>
          </div>
        </template>
      </el-table-column>


    </el-table>


 <!-- Update Dialog -->
 <el-dialog title="Update Financial Document" v-model="updateDialogVisible" width="50%">
  <el-form :model="editDocument">
    <el-form-item label="Receipt Number">
      <el-input v-model="editDocument.receipt_number" disabled></el-input>
    </el-form-item>

    <el-form-item label="Total Amount">
      <el-input v-model="editDocument.total_amount" disabled></el-input>
    </el-form-item>

    <el-form-item label="Value Received">
      <el-input v-model="editDocument.value_received"></el-input>
    </el-form-item>

    <el-form-item label="Discount">
      <el-input v-model="editDocument.discount" type="number" placeholder="Enter discount"></el-input>
    </el-form-item>

    <el-form-item label="Payment Method">
      <el-select v-model="editDocument.payment_method">
        <el-option label="Cash" value="cash"></el-option>
        <el-option label="Instrument" value="instrument"></el-option>
      </el-select>
    </el-form-item>

    <!-- Subscription Fees -->
    <el-form-item label="Subscription Fees">
      <div v-for="(fee, index) in editDocument.student_fees" :key="fee.subscription_fee_id">
        <el-input v-model="fee.amount" placeholder="Amount"></el-input>
      </div>
    </el-form-item>
  </el-form>

  <span slot="footer" class="dialog-footer">
    <el-button @click="updateDialogVisible = false">Cancel</el-button>
    <el-button type="primary" @click="updateDocument">Update</el-button>
  </span>
</el-dialog>




    <!-- PDF Dialog -->
    <el-dialog
      v-model="pdfDialogVisible"
      width="70%"
      :before-close="handlePdfDialogClose"
    >
      <iframe
        v-if="pdfUrl"
        :src="pdfUrl"
        width="100%"
        height="600px"
        style="border: none;"
      ></iframe>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pdfDialogVisible = false">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage,ElDialog } from 'element-plus';
import ImgStudent from "../../assets/img/student.png"
import logoSchool from "@/assets/img/logo-school.png";
import signatureHareth from "@/assets/img/signature_hareth.png";
import TajawalBold from "@/assets/fonts/Tajawal-Bold.ttf";
import WaterMark from "../../assets/img/logo-school-one.png"
  import QrcodeVue, { QrcodeCanvas, QrcodeSvg } from 'qrcode.vue'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default {
  data() {
    return {
      searchQuery: "",
      financialDocuments: [],
      updateDialogVisible: false,
      editDocument: {}, // Holds the financial document to edit
      pdfDialogVisible: false,
      pdfUrl: null,
    };
  },
  components:{
    QrcodeVue,
    QrcodeCanvas,
QrcodeSvg
  },
  methods: {
    operationCellClass() {
      alert("s")
      return 'operation-cell-align-right';
    },
    openUpdateDialog(document) {
      this.editDocument = JSON.parse(JSON.stringify(document)); // Clone the document data
      this.updateDialogVisible = true;
    },
    fetchFinancialDocuments() {
      api.get('/financial-documents', {
        params: { search: this.searchQuery }
      })
      .then(response => {
        this.financialDocuments = response.data;
      })
      .catch(error => {
        console.error('Error fetching financial documents:', error);
      });
    },
    updateDocument() {
      // Prepare data for update
      const updateData = {
        discount: this.editDocument.discount || 0, // Ensure discount is sent
        payment_method: this.editDocument.payment_method,
        value_received: this.editDocument.value_received,
      };

      // Include student_fees if they are being edited
      if (this.editDocument.student_fees) {
        updateData.student_fees = this.editDocument.student_fees.map(fee => ({
          subscription_fee_id: fee.subscription_fee_id,
          amount: fee.amount,
          student_id: fee.student_id // Ensure student_id is included if necessary
        }));
      }

      api
        .put(`/financial-documents/${this.editDocument.id}`, updateData)
        .then((response) => {
          this.$message.success("Financial document updated successfully");
          this.updateDialogVisible = false;
          this.fetchFinancialDocuments(); // Refresh the table
        })
        .catch((error) => {
          console.error("Error updating financial document:", error);
          this.$message.error("Failed to update the document");
        });
    },
    deleteDocument(id) {
      this.$confirm("Are you sure you want to delete this document?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      })
      .then(() => {
        api.delete(`/financial-documents/${id}`).then(response => {
          const valueReceived = response.data.value_received;

          // Show success message
          this.$message.success(`Financial document deleted successfully. The amount of ${valueReceived} has been subtracted from the treasury.`);

          // Fetch updated documents
          this.fetchFinancialDocuments();
        })
        .catch(error => {
          console.error('Error deleting financial document:', error);
          this.$message.error("Failed to delete the document");
        });
      })
      .catch(() => {
        this.$message.info("Delete canceled");
      });
    },
    printDocument(document) {
  const printWindow = window.open('', '_blank', 'width=800,height=600');
  const qrCodeUrl = `http://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(document.receipt_number)}&size=50x50`;

  // Get current date and time
  const now = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
  const formattedDateTime = now.toLocaleString('en-US', options);

  printWindow.document.write(`
    <html>
    <head>
      <title>Financial Document</title>
      <style type="text/css">
        @font-face {
          font-family: 'TajawalBold';
          src: url('${TajawalBold}') format('truetype');
        }
        body, table, td, a {
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
        }
        table, td {
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
        }
        img {
          -ms-interpolation-mode: bicubic;
        }
        body {
          margin: 0 !important;
          padding: 0 !important;
          background-color: #070037;
          width: 100% !important;
        }
        table {
          border-collapse: collapse !important;
        }
        .invoice-container {
          max-width: 800px;
          background-color: #ffffff;
          margin: auto;
          padding: 35px;
          display: flex;
          flex-direction: column;
          position: relative;
          z-index: 1;
        }
        .details-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 15px;
          text-align: right;
        }
        .signature-container {
          align-self: flex-start;
          margin-top: auto;
        
          position: relative;
        }
        .date-time {
          position: absolute;
          bottom: 0;
          left: 0;
            text-align: right;
          font-size: 14px;
          color: #777777;
        }
        h1, h2, h5, p {
          font-family: 'TajawalBold', Arial, sans-serif;
          color: #333333;
        }
        h5 {
          color: white;
          margin-top: 15px;
          font-size: 20px;
        }
        h1 {
          font-size: 24px;
        }
        h2 {
          font-size: 20px;
          color: #23C467;
        }
        p {
          font-size: 16px;
          color: #777777;
        }
        .subscription-fees {
          grid-column: 1 / -1;
          padding-top: 15px;
        }
        .subscription-fee-item {
          border-bottom: 1px solid #ddd;
          padding: 10px 0;
          list-style-type: none;
        }
        .gradient-header {
          height: 6px;
          background-image: linear-gradient(to right, #b91bAb, #5a3aa5);
        }
        .header-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 15px;
        }
        /* Watermark CSS */
        .watermark {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: url('${WaterMark}');
          background-size: 80%;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 0.1;
          z-index: 9999;
        }
      </style>
    </head>

    <body>
      <div class="watermark"></div>
      <table width="100%">
        <tr>
          <td align="center" valign="top" style="background-color: #ffffff; font-size:0; padding: 35px 35px 0;">
            <div class="header-container">
              <!-- Logo on the left -->
              <h1>
                <img src="${logoSchool}" width="180" height="80" style="display: block; border: 0px;" alt="School Logo" />
              </h1>
              <!-- QR Code on the right -->
              <img src="${qrCodeUrl}" alt="QR Code" style="width: 90px; height: 90px;" />
            </div>
          </td>
        </tr>
        <tr>
          <td align="center" style="padding: 0 15px 20px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center">
                  <h5>
                    ${document.parent_name} : أهـلاً بالسيـد/ة 
                    <br/>
                    مرحبا بك معنا! نحن نحب أن تكون جزءًا من مجتمع مدرستنا الماســة
                  </h5>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td align="center">
            <div class="invoice-container">
              <div class="details-container">
                <p>${document.receipt_number} رقم الإيصال</p>
                <p>المبلغ الإجمالي: ${document.total_amount}</p>
                <p>المبلغ النهائي: ${document.final_amount}</p>
                <p>المبلغ المستلم: ${document.value_received}</p>

                <div class="subscription-fees">
                  <h2>وذلك لغــرض</h2>
                  <ul>
                    ${document.subscription_fees.map(fee => `
                      <li class="subscription-fee-item">
                        <div>${fee.student_name}: </div>
                        <div>${fee.amount}: القيمة</div>
                        <div>${fee.category}: الفئة</div>
                        <div>${fee.sub_category}: الفئة الفرعية</div>
                      </li>
                    `).join('')}
                  </ul>
                </div>
              </div>
              <div class="signature-container">
                <img src="${signatureHareth}" alt="Signature" style="width: 200px; height: auto;" />
                <p>مدير إدارة الشؤون المالية</p>
                <!-- Displaying Date and Time -->
               
              </div>
               <div class="date-time">${formattedDateTime}</div>
            </div>
          </td>
        </tr>
      </table>
    </body>
    </html>
  `);

  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
  printWindow.close();
},

    handlePdfDialogClose(done) {
      this.pdfDialogVisible = false;
      this.pdfUrl = null;
    },
    getStudentCellStyle(row) {
      return {
        backgroundColor: row.student_name === 'N/A' ? '#f8d7da' : '#d4edda',
        color: row.student_name === 'N/A' ? '#721c24' : '#155724',
        padding: '10px',
        borderRadius: '5px'
      };
    },
    // Custom cell styling for value received
    getValueReceivedCellStyle(row) {
      return {
        backgroundColor: row.value_received < row.final_amount ? '#fff3cd' : '#d1ecf1',
        color: row.value_received < row.final_amount ? '#856404' : '#0c5460',
        padding: '10px',
        borderRadius: '5px'
      };
    },
    // Custom cell styling for sibling names
    getSiblingCellStyle() {
      return {
        backgroundColor: '#fff3cd',
        color: '#856404',
        padding: '5px',
        borderRadius: '3px',
        marginBottom: '5px',
      };
    },
    // Custom cell styling for subscription fees
    getFeeCellStyle() {
      return {
        backgroundColor: '#e2e3e5',
        color: '#383d41',
        padding: '5px',
        borderRadius: '5px',
        marginBottom: '5px'
      };
    },
  },
  mounted() {
    this.fetchFinancialDocuments();
  }
};
</script>

<style scoped>
td{
  text-align: right !important;
}
.el-table >>> .operation-cell-align-right {
  text-align: right !important;
}
.el-table >>> .el-table__cell {
  text-align: right !important;
}
.student-name-cell {
  font-weight: bold;
}

.siblings-cell ul {
  padding-left: 15px;
  list-style-type: disc;
}

.subscription-fees-cell .fee-details {
  margin-bottom: 5px;
}
</style>
